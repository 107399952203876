import http from '@/http-common'

export default class InternalChatServices{

    async getAll(){
        let res = await http.get(`v1/chatinternal`);
        return res.data;
    }

    async getHistoryMessage(userIdRemetente, userIdDestinatario, pageNumber, pageSize){
        let res = await http.get(`v1/chatinternal/${userIdRemetente}/${userIdDestinatario}/history?PageNumber=${pageNumber}&PageSize=${pageSize}`);
        return res.data;
    }

    async sendMessage(data, FunctionProgress){
        let res = await http.post(`v1/chatinternal/send-message`, data,
        {
            onUploadProgress: (event) => {
                let progress = Math.round((event.loaded * 100) / event.total);
                FunctionProgress(progress);
            },
        });
        return res.data;
    }

    async readMessage(patnerId){
        let res = await http.post(`v1/chatinternal/read-message/${patnerId}`);
        return res.data;
    }
}