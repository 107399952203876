<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="comet_two_rotate_rotate"
      d="M14.6933 7.09298C14.5422 7.10235 14.5211 6.91024 14.6569 6.84465C26.4008 4.92364 26.5039 14.7126 22.9629 16.6277C22.6407 16.7993 22.2793 16.8837 21.9144 16.8726C21.5495 16.8615 21.1938 16.7553 20.8826 16.5645C20.4431 16.2648 20.1404 15.8029 20.0409 15.2803C19.9414 14.7576 20.0533 14.2169 20.352 13.7767C21.7506 11.2501 22.471 7.20309 14.6933 7.09298Z"
      fill="url(#paint0_linear_5635_7759)"
    />
    <!-- <path
      
      d="M17.3862 11.7782C17.3698 11.6248 17.5549 11.5932 17.6276 11.7279C20.5864 19.5325 15.3926 23.0759 9.17395 23.1356C6.40958 23.1005 4.70059 22.4164 3.93219 21.1115C3.74018 20.8315 3.63251 20.5024 3.62195 20.163C3.61139 19.8237 3.69838 19.4885 3.87262 19.1971C4.04685 18.9057 4.30101 18.6704 4.60493 18.5191C4.90885 18.3679 5.24979 18.307 5.5873 18.3436C6.53609 18.3436 7.38766 18.8918 10.1087 19.1026C12.6903 19.317 17.9754 18.2909 17.3862 11.7782Z"
      fill="url(#paint1_linear_5635_7759)"
    /> -->
    <path
      class="comet_one_rotate"
      d="M1.50594 14.9847C1.56568 15.1264 1.39701 15.2131 1.29042 15.1018C-1.05227 10.5395 0.0312207 6.38471 2.64683 3.85694C4.64392 2.04568 7.0957 0.81122 9.74006 0.285525C12.3844 -0.240171 15.1219 -0.0373309 17.66 0.872359C19.9523 1.66067 20.3259 3.18811 19.9792 4.28097C19.6325 5.37384 18.6322 5.78263 16.8424 5.27076C10.0122 3.21388 -0.473629 5.16416 1.50594 14.9847Z"
      fill="url(#paint2_linear_5635_7759)"
    /> 
    <path
      class="comet_three_rotate_rotate"
      d="M11.3869 16.1313C11.4837 16.1229 11.5033 16.2387 11.4191 16.2848C2.70133 18.2113 3.13626 11.455 4.77309 10.0201C4.98661 9.79632 5.25979 9.63843 5.56026 9.5651C5.86074 9.49176 6.17594 9.50607 6.46854 9.60631C7.19361 9.83217 7.37803 10.6919 7.04333 11.5839C6.42064 13.203 6.42001 16.2642 11.3869 16.1313Z"
      fill="url(#paint3_linear_5635_7759)"
    />
     <path
      d="M12.579 14.6083C14.3658 14.6083 15.8143 13.1294 15.8143 11.3051C15.8143 9.48084 14.3658 8.00195 12.579 8.00195C10.7922 8.00195 9.34375 9.48084 9.34375 11.3051C9.34375 13.1294 10.7922 14.6083 12.579 14.6083Z"
      fill="white"
    /> 
    <defs>
      <linearGradient
        id="paint0_linear_5635_7759"
        x1="23.2776"
        y1="16.4214"
        x2="13.6948"
        y2="7.69702"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.234" stop-color="white" stop-opacity="0.702" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5635_7759"
        x1="6.01363"
        y1="22.8023"
        x2="12.0409"
        y2="8.89356"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.172" stop-color="white" stop-opacity="0.702" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5635_7759"
        x1="11.3315"
        y1="-1.83346"
        x2="5.38469"
        y2="16.3917"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.179" stop-color="white" stop-opacity="0.702" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5635_7759"
        x1="4.9431"
        y1="10.0138"
        x2="11.5227"
        y2="16.3809"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.18" stop-color="white" stop-opacity="0.702" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CosmosLoading",
};
</script>


<style lang="scss" scoped>
.comet_one_rotate {
  transform-origin: 50% 30%;
  animation: comet_one 2.4s linear infinite;
}

.comet_two_rotate_rotate {
  transform-origin: 50% 30%; 
  animation: comet_two 1.5s linear infinite;
  animation-delay: 3s;
}

.comet_three_rotate_rotate {
  transform-origin: 50% 30%;  
  animation: comet_three 2s linear infinite;
  
}


@keyframes comet_one{
  0%{
    transform: rotate(-121deg) translate(-2px, -5px) scale(0.3);
    opacity: 0.5;
  }
  80%, 90%{
    opacity: 1;
  }
  100%{
    transform: rotate(-19deg) translate(1px, -2px) scale(0.8);
    opacity: 0;
  }
}

@keyframes comet_two{
  0%{
    transform: rotate(-61deg) translate(-5px, 3px) scale(0.4);
    opacity: 0;
  }
  90%{
    opacity: 1;
  }
  100% {
    transform: rotate(86deg) translate(3px, -5px) scaleX(1) scaleY(1.65);
    opacity: 0;
  }
}

@keyframes comet_three{
  0%{
    transform: rotate(41deg) translate(3px, -2px) scale(0.5);
    opacity: 0.4;
  }
  90%{
    opacity: 1;
  }
  100% {
    transform: rotate(115deg) translate(5px, -15px) scaleX(1) scaleY(1.5);
    opacity: 0;
  }
}
</style>
