import http from '../http-common'

export default class TabulationServices {

    async saveTabulationInformations(tabulation_information){
        let res = await http.post(`v1/tabulation/save-tabulation-informations`, tabulation_information);
        return res.data;
    }

    async getTabulationByAttendanceId(attendance_id)
    {
        let res = await http.get(`v1/tabulation/get-tabulation-by-attendance?attendanceId=${attendance_id}`);
        return res.data;
    }
}