<template>
    <div class="col-12 row flex" v-if="data.attendanceId">
        <div class="flex col-12 row justify-center items-center" v-if="loading">
            <q-spinner-hourglass color="primary" size="2rem" />
        </div>
        <div class="row col-12 flex card-form-history">
            <div class="col-12 row flex card-history" v-for="item in attendance_list_hitory"
                @click="getHistoryMessage(item)">
                <div class="flex justify-center col-md-3 col-lg-2 items-center q-px-md">
                    <q-icon v-if="item.canal == 'TICKET'" name="email" color="primary" size="1.5rem" />
                    <img  v-else
                        :src="`${url_aws_bucket}icons/${item.canal_atendimento.toLowerCase()}_logo.svg`">
                </div>
                <div class="col-md-9 col-lg-10">
                    <div class="col-12">
                        <label class="label-date-init">Protocolo: {{ item.protocolo }}</label>
                    </div>
                    <div class="col-12">
                        <label class="label-date-init">Iniciado em: {{ date.formatDate(item.inicio_atendimento,
                                'DD/MM/YYYY HH:mm')
                        }}</label>
                    </div>
                    <div class="col-12">

                        <label class="label-date-end" v-if="item.fim_atendimento == null">Em aberto</label>
                        <label class="label-date-end" v-else>Finalizado em: {{ date.formatDate(item.fim_atendimento,
                                'DD-MM-YYYY HH:mm')
                        }}</label>
                    </div>
                </div>
            </div>
        </div>
        <q-dialog v-model="dialog_history_attendance" persistent transition-duration="200">
            <q-card class="dialog-history-attendace col-11">
                <q-toolbar class="toobar-attendance-selected q-py-sm">
                    <div class="q-pt-sm">
                        <img class="reponsive-image"
                            :src="`${url_aws_bucket + attendance_selected.canal_atendimento.toLowerCase()}_logo.svg`">
                    </div>

                    <div class="flex row col-11 q-pl-md">
                        <div class="col-12">
                            <label class="text-bold" v-if="attendance_selected.nome != null">{{ attendance_selected.nome
                            }}</label>
                            <label class="text-bold" v-else>Atendimento realizado pelo canal de
                                {{ attendance_selected.canal_atendimento }}</label>
                        </div>
                        <div class="col-12 row flex">
                            <div class="col-3">
                                <label><b>Protocolo:</b> {{ attendance_selected.protocolo }}</label>
                            </div>
                            <div class="col-3">
                                <label><b>Atendido por:</b> {{ attendance_selected.atendente }}</label>
                            </div>
                            <div class="col-4">
                                <label v-if="attendance_selected.fim_atendimento == null"><b>Status:</b> Em
                                    aberto</label>
                                <label v-else><b>Status:</b> Finalizado em {{
                                        date.formatDate(attendance_selected.fim_atendimento,
                                            'DD/MM/YYYY HH:mm')
                                }}</label>
                            </div>
                            <div class="col-12 row flex" v-if="tabulation_history == ''">
                                <label><b>Atendimento sem tabulação</b></label>
                            </div>
                        </div>
                    </div>

                    <q-btn flat round dense icon="close" v-close-popup />
                </q-toolbar>

                <q-card-section style="background-color: #cecece;">
                    <div class="row flow col-12">
                        <div class="q-pa-sm dialog-history-attendance-body" :class="[tabulation_history != '' ? 'col-9' : 'col-12' ]" id="scrollAreaHistoryAttendance">
                            <div v-for="message in messages" :key="message.id_mensagem">
                                <q-chat-message :text="[message.mensagem]" size="6" :stamp="date.formatDate(message.data_mensagem,
                                            'DD/MM/YYYY HH:mm')"
                                    v-if="message.cliente" bg-color="container-answer-menssage-chat" />
                                <q-chat-message size="6" v-else :text="[message.mensagem]"
                                    :stamp="date.formatDate(message.data_mensagem,
                                            'DD/MM/YYYY HH:mm')" sent bg-color="container-send-menssage-chat" />
                            </div>
                        </div>
                        <div class="col-3 q-pa-sm tabulation-attendance-history" v-if="tabulation_history != ''">
                            <div class="col-12 row flex justify-center">
                                <label class="label-tabulation-attendance">Tabulação do atendimento</label>
                            </div>
                            <div class="col-12 row flex">
                                <div class="col-12" v-for="item in tabulation_history"
                                    :key="item.id_tabulador_parametro">
                                    <div class="col-12 q-mt-sm" v-if="item.tipo_parametro == 'shortText'">
                                        <div class="col-12">
                                            <label>{{ item.nome_parametro }}</label>
                                        </div>
                                        <div class="col-12">
                                            <q-input class="col-md-10" readonly disable
                                                :rules="item.obrigatorio_parametro ? rules_empty : ''" lazy-rules
                                                outlined bottom-slots type="text" bg-color="white" dense
                                                v-model="item.valor_informacao"></q-input>
                                        </div>
                                    </div>
                                    <div class="col-12" v-if="item.tipo_parametro == 'logic'">
                                        <div class="col-12">
                                            <label>{{ item.nome_parametro }}</label>
                                        </div>
                                        <div class="col-12">
                                            <q-field disable  v-model="item.valor_informacao" hide-bottom-space borderless disabled
                                                lazy-rules :rules="!item.obrigatorio_parametro ? rules_empty : ''">
                                                <q-radio disable v-model="item.valor_informacao" :val="radioOption.value"
                                                    :label="radioOption.label"
                                                    v-for="radioOption in [{ label: 'Sim', value: 'sim' }, { label: 'Não', value: 'nao' }]" />

                                            </q-field>
                                        </div>
                                    </div>
                                    <div class="col-12 q-mt-sm" v-if="item.tipo_parametro == 'longText'">
                                        <div class="col-12">
                                            <label>{{ item.nome_parametro }}</label>
                                        </div>
                                        <div class="col-12">
                                            <q-input disable  type="textarea" cols="1" rows="2" class="col-md-10" outlined readonly
                                                bottom-slots bg-color="white" dense v-model="item.valor_informacao"
                                                lazy-rules :rules="item.obrigatorio_parametro ? rules_empty : ''">
                                            </q-input>
                                        </div>
                                    </div>
                                    <div class="col-12 q-mb-sm" v-if="item.tipo_parametro === 'list'">
                                        <div class="col-12">
                                        <label>{{ item.nome_parametro }}</label>
                                            </div>
                                        <div class="col-12">
                                            <label>{{ item.valor_informacao }}</label>                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </q-dialog>

        <q-dialog v-model="dialog_history_attendance_ticket" persistent transition-duration="200">
            <q-card class="dialog-history-attendace col-11">
                <q-toolbar class="toobar-attendance-selected q-py-sm">
                    <div class="q-pt-sm">
                        <q-icon name="email" color="primary" size="1.8rem" />
                    </div>

                    <div class="flex row col-11 q-pl-md">
                        <div class="col-12">
                            <label class="text-bold" v-if="attendance_selected.nome != null">{{ attendance_selected.nome
                            }}</label>
                            <label class="text-bold" v-else>Atendimento realizado pelo canal de
                                {{ attendance_selected.canal_atendimento }}</label>
                        </div>
                        <div class="col-12 row flex">
                            <div class="col-3">
                                <label><b>Protocolo:</b> {{ attendance_selected.protocolo }}</label>
                            </div>
                            <div class="col-3">
                                <label><b>Atendido por:</b> {{ attendance_selected.atendente }}</label>
                            </div>
                            <div class="col-4">
                                <label v-if="attendance_selected.fim_atendimento == null"><b>Status:</b> Em
                                    aberto</label>
                                <label v-else><b>Status:</b> Finalizado em {{
                                        date.formatDate(attendance_selected.fim_atendimento,
                                            'DD/MM/YYYY HH:mm')
                                }}</label>
                            </div>
                            <div class="col-12 row flex" v-if="tabulation_history == ''">
                                <label><b>Atendimento sem tabulação</b></label>
                            </div>
                        </div>
                    </div>

                    <q-btn flat round dense icon="close" v-close-popup />
                </q-toolbar>

                <q-card-section style="background-color: #cecece;">
                    <div class="row flow col-12">
                        <div class="q-pa-sm dialog-history-attendance-body scroll" :class="[tabulation_history != '' ? 'col-9' : 'col-12' ]" id="scrollAreaHistoryAttendance">
                            <q-list borderless class="rounded-borders">
                                <div v-for="(msg, index) in messages" :key="msg.id_mensagem">
                                <q-expansion-item v-if="msg.evento == 'nova_mensagem'"
                                                    header-class="text-container-answer-menssage-chat text-black"
                                                    expand-icon-class="text-black"
                                                    @click="toggleViewMessage(index)">
                                    <template v-slot:header>
                                    <q-item-section avatar>
                                        <q-avatar v-if="msg.cliente == 1" class="icon-received" icon="o_forward_to_inbox"
                                                color="primary" text-color="white"/>
                                        <q-avatar v-else icon="o_forward_to_inbox" color="green-5" text-color="white"/>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-item-label v-if="msg.cliente == 1" lines="1"><b>{{ msg.cliente_nome }}</b>
                                        <label v-if="msg.OpenViewMessage">
                                            {{ "<" + msg.email_de + ">" }}</label>
                                        </q-item-label>
                                        <q-item-label v-else lines="1"><b>Plataforma Digital</b>
                                        <label v-if="msg.OpenViewMessage">
                                            {{ "<" + msg.email_de + ">" }}</label>
                                        </q-item-label>
                                        <q-item-label v-if="msg.OpenViewMessage" caption lines="1">Para: {{ msg.email_para }}
                                        </q-item-label>
                                        <q-item-label v-else caption lines="1" v-html="msg.mensagem"/>
                                    </q-item-section>

                                    <q-item-section side top>
                                        <q-item-label>{{ msg.data_registro }}</q-item-label>
                                    </q-item-section>
                                    </template>

                                    <q-card style="background: #c9e1e4 !important">
                                    <q-card-section>
                                        <q-item-label v-html="msg.mensagem_html"/>
                                    </q-card-section>

                                    <q-separator/>

                                    <q-card-section class="row" v-if="msg.messageTicketAttachement.length > 0">
                                        <q-item-label class="col-12 q-mb-sm"><b>{{ msg.messageTicketAttachement.length }} Anexos</b>
                                        </q-item-label>
                                        <a v-for="(
                                            attachment, index
                                        ) in msg.messageTicketAttachement" :key="index" :title="attachment.file_name"
                                        :href="url_aws_s3 + attachment.file_path" download target="_blank">
                                        <q-card style="width: 150px" class="q-ml-sm">
                                            <q-card-actions align="right">
                                            <q-item-label caption lines="1" v-html="attachment.file_name"/>
                                            </q-card-actions>
                                        </q-card>
                                        </a>
                                    </q-card-section>
                                    </q-card>
                                </q-expansion-item>

                                <q-item v-ripple v-else class="text-container-send-menssage-chat text-black"
                                        expand-icon-class="text-black" style="background: transparent !important">
                                    <q-item-section avatar>
                                    <q-avatar icon="priority_high" color="yellow-9" text-color="white"/>
                                    </q-item-section>

                                    <q-item-section>
                                    <q-item-label><b>{{ msg.descricao_evento }}</b></q-item-label>
                                    </q-item-section>

                                    <q-item-section side top>
                                    <q-item-label style="margin-right: 39px; color: black">{{
                                        msg.data_registro
                                        }}
                                    </q-item-label>
                                    </q-item-section>
                                </q-item>

                                <q-separator/>
                                </div>
                            </q-list>
                        </div>
                        <div class="col-3 q-pa-sm tabulation-attendance-history" v-if="tabulation_history != ''">
                            <div class="col-12 row flex justify-center">
                                <label class="label-tabulation-attendance">Tabulação do atendimento</label>
                            </div>
                            <div class="col-12 row flex">
                                <div class="col-12" v-for="item in tabulation_history"
                                    :key="item.id_tabulador_parametro">
                                    <div class="col-12 q-mt-sm" v-if="item.tipo_parametro == 'shortText'">
                                        <div class="col-12">
                                            <label>{{ item.nome_parametro }}</label>
                                        </div>
                                        <div class="col-12">
                                            <q-input class="col-md-10" readonly
                                                :rules="item.obrigatorio_parametro ? rules_empty : ''" lazy-rules
                                                outlined bottom-slots type="text" bg-color="white" dense
                                                v-model="item.valor_informacao"></q-input>
                                        </div>
                                    </div>
                                    <div class="col-12" v-if="item.tipo_parametro == 'logic'">
                                        <div class="col-12">
                                            <label>{{ item.nome_parametro }}</label>
                                        </div>
                                        <div class="col-12">
                                            <q-field v-model="item.valor_informacao" hide-bottom-space borderless disabled
                                                lazy-rules :rules="!item.obrigatorio_parametro ? rules_empty : ''">
                                                <q-radio v-model="item.valor_informacao" :val="radioOption.value"
                                                    :label="radioOption.label"
                                                    v-for="radioOption in [{ label: 'Sim', value: 'sim' }, { label: 'Não', value: 'nao' }]" />

                                            </q-field>
                                        </div>
                                    </div>
                                    <div class="col-12 q-mt-sm" v-if="item.tipo_parametro == 'longText'">
                                        <div class="col-12">
                                            <label>{{ item.nome_parametro }}</label>
                                        </div>
                                        <div class="col-12">
                                            <q-input type="textarea" cols="1" rows="2" class="col-md-10" outlined readonly
                                                bottom-slots bg-color="white" dense v-model="item.valor_informacao"
                                                lazy-rules :rules="item.obrigatorio_parametro ? rules_empty : ''">
                                            </q-input>
                                        </div>
                                    </div>
                                    <div class="col-12 q-mb-sm" v-if="item.tipo_parametro === 'list'">
                                        <div class="col-12">
                                        <label>{{ item.nome_parametro }}</label>
                                            </div>
                                        <div class="col-12">
                                            <label>{{ item.valor_informacao }}</label>                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </q-dialog>
    </div>
</template>
<script setup>
import { ref, onMounted, watchEffect } from 'vue'
import { useStore } from 'vuex';
import { useQuasar, date } from 'quasar';
import { ENV } from '@/utils/env'
import ChatServices from '@/services/ChatServices';
import ContactServices from '@/services/ContactServices.js';
import ReportServices from '@/services/ReportServices.js';
import TabulationServices from '@/services/TabulationServices';
import QueueServices from "@/services/QueueServices";
import TicketServices from "@/services/TicketServices";


const props = defineProps({ data: Object })
const store = useStore();
const url_aws_bucket = ENV.URL_AWS_BUCKET
const _chatServices = new ChatServices();
const _contactServices = new ContactServices();
const _reportServices = new ReportServices();
const _tabulationServices = new TabulationServices();
const _queueServices = new QueueServices();
const _ticketServices = new TicketServices();
const $q = useQuasar();
let loading = ref(false);
let contact = ref({})
let attendance_list_hitory = ref([])
let dialog_history_attendance = ref(false)
let dialog_history_attendance_ticket = ref(false)
let messages = ref([])
let attendance_selected = ref({})
let tabulation_history = ref([])

function _getDataContact(id_contact) {
    if (!props.data.attendanceId) {
        return;
    }
    loading.value = true;
    _contactServices.search(id_contact).then(response => {
        contact.value = response
        _getHistoryAttendance();
        loading.value = false;
    })
}

function getHistoryMessage(item) {
    $q.loading.show();
    attendance_selected.value = item;
    _getHistoryTabulation(item);
    if(item.canal == 'TICKET'){
      _ticketServices.getMessageForTicket(item.id_atendimento).then(response => {
            messages.value = response;

            dialog_history_attendance_ticket.value = true
            $q.loading.hide();
            _scroll()
        })
    }else{
        _reportServices.getMessagesAttenadaceChat(item.id_atendimento).then(response => {
            messages.value = response;

            dialog_history_attendance.value = true
            $q.loading.hide();
            _scroll()
        })
    }

}
function _getHistoryTabulation(item) {
    attendance_selected.value = item;

    _queueServices.getTabulationByAttendanceId(props.data.idQueue, props.data.attendanceId).then(response => {
        tabulation_history.value = response;

    })

}

function _scroll() {
    setTimeout(() => {
        let sizeScreen = document.getElementById("scrollAreaHistoryAttendance").offsetHeight;
        document.getElementById("scrollAreaHistoryAttendance").scrollBy({
            top: sizeScreen,
            left: 0,
            behavior: "smooth"
        });
    }, 100);
}

function _getHistoryAttendance() {
    if (!props.data.attendanceId) {
        return;
    }
    _chatServices.getAllAttendancesForContact(props.data.contactId).then(response => {
        attendance_list_hitory.value = response
    })
}

watchEffect(() => {
    _getDataContact(props.data.contactId)

});


</script>
<style lang="scss" scoped>
.card-form-history {
    max-height: 75vh;
    overflow-y: auto;
}

.label-date-init {
    font-size: 12px;
    margin-bottom: 8px;
    opacity: 1;
    text-transform: uppercase;
    font-weight: 700;

}

.reponsive-image {
    width: calc(1rem + 2vw) !important;
    height: calc(1rem + 2vw) !important;
}

.toobar-attendance-selected {
    background-color: #cecece83;
}

.dialog-history-attendace {
    width: 80%;
    overflow: hidden;

}

.dialog-history-attendance-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 80vh!important;
}

.card-history {
    border-bottom: 1px solid #cecece;
}

.label-tabulation-attendance {
    font-size: 15px;
    font-weight: bold;
}

.card-history * {
    cursor: pointer;
}

.label-date-end {
    font-size: 10px;
    margin-bottom: 8px;
    opacity: .6;
    text-transform: uppercase;
    font-weight: 700;
}

.scroll {
    height: 81vh;
    /*54vh;*/
    padding-right: 5px;
    max-height: 95vh;
    overflow: auto;
    overflow-x: hidden;
}

</style>