import { Inviter, Registerer, SessionState, UserAgent } from "sip.js";

export default class PabxProvider {
  
    constructor(server, username, password, onInvite){
    
        this.calls = [];
        this.server = server;
        let uri = UserAgent.makeURI(`sip:${username}@${server}`);
   
        let transportOptions = {
            server: `wss://${server}:8089/ws`
        };

        let userAgentOptions = {
            logBuiltinEnabled: false,
            authorizationPassword: password,
            authorizationUsername: username,
            delegate: {
              onInvite: (invitation) => onInvite(invitation) 
            },
            transportOptions,
            uri
        };
      
        this.userAgent = new UserAgent(userAgentOptions);
    }

    async connect(){
      await this.userAgent.start().then(() => {
        let objectRegister = new Registerer(this.userAgent);
        objectRegister.register();
      });
    }

    call(customer, establishing, established, terminated) {
        let inviter = new Inviter(this.userAgent, UserAgent.makeURI(`sip:${customer}@${this.server}`));
        inviter.stateChange.addListener((state) => {
        console.log(`Estado da sessão foi alterado para ${state}`);
        switch (state) {
            case SessionState.Initial:
            break;
            case SessionState.Establishing:
              establishing(customer);
            break;
            case SessionState.Established:
              established(customer);
              this.setupRemoteMedia(inviter);
              break;
            case SessionState.Terminating:
            // fall through
            case SessionState.Terminated:
              terminated(customer);
              this.cleanupMedia();
            break;
            default:
            throw new Error("Unknown session state.");
        }
        })
        inviter.invite();
        
        this.addInviter(customer, inviter);
    }

    addInviter(customer, inviter){
      this.calls.push({
        customer: customer,
        inviter: inviter
      })
    }

    setupRemoteMedia(session) {
        console.log('****************************************************************************');
        console.log(session);
      
        const remoteStream = new MediaStream();
        session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
          if (receiver.track) {
            remoteStream.addTrack(receiver.track);
          }
        });

  
        document.getElementById('mediaElement').srcObject = remoteStream;
        document.getElementById('mediaElement').play();
    }

    cleanupMedia() {
        document.getElementById('mediaElement').srcObject = null;
        document.getElementById('mediaElement').pause();
    }

    getIndexInviter(customer){
      var indexCall = this.calls.findIndex(call => {
        return call.customer == customer
      });
      return indexCall;
    }

    hangup(customer){
      var indexCall = this.getIndexInviter(customer);
      let inviter = this.calls[indexCall].inviter;

      switch (inviter.state) {
        case SessionState.Initial:
        case SessionState.Establishing:
          inviter.cancel();
          break;
        case SessionState.Established:
          // An established session
          inviter.bye();
          break;
        case SessionState.Terminating:
        case SessionState.Terminated:
          // Não é possível encerrar uma sessão que já foi encerrada
          break;
      }

      this.calls.splice(indexCall, 1);
    }

    transfer(customer, transferNumber){
      var indexCall = this.getIndexInviter(customer);
      let inviter = this.calls[indexCall].inviter;

      let transferTarget = UserAgent.makeURI(`sip:${transferNumber}@${this.server}`);

      switch (inviter.state) {
        case SessionState.Initial:
        case SessionState.Establishing:
          break;
        case SessionState.Established:
          // An established session
          inviter.refer(transferTarget);
          this.calls.splice(indexCall, 1);
          return true;
          break;
        case SessionState.Terminating:
        case SessionState.Terminated:
          // Não é possível encerrar uma sessão que já foi encerrada
          break;
      }

      return false;
    }

    mute(customer, state){
      var indexCall = this.getIndexInviter(customer);
      let inviter = this.calls[indexCall].inviter;

      var pc = inviter.sessionDescriptionHandler.peerConnection;

      switch (inviter.state) {
        case SessionState.Initial:
        case SessionState.Establishing:
          break;
        case SessionState.Established:
          pc.getLocalStreams().forEach(function (stream) {
            stream.getAudioTracks().forEach(function (track) {
                track.enabled = state;
            });
          });
          break;
        case SessionState.Terminating:
        case SessionState.Terminated:
          break;
      }
    }

    hold(customer){
      var indexCall = this.getIndexInviter(customer);
      let inviter = this.calls[indexCall].inviter;

      switch (inviter.state) {
        case SessionState.Initial:
        case SessionState.Establishing:
          break;
        case SessionState.Established:
          inviter.hold();
          break;
        case SessionState.Terminating:
        case SessionState.Terminated:
          break;
      }
    }
}


