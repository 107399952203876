<template>
  
<div v-if="props.pause" class="pos-timer-container">
  <Digit :digit="timerDown.hours" /> :
  <Digit :digit="timerDown.minutes" /> :
  <Digit :digit="timerDown.seconds"/>
</div>

<div v-else class="timer-container">
  <Digit :digit="timer.hours" /> :
  <Digit :digit="timer.minutes" /> :
  <Digit :digit="timer.seconds"/>
</div>

</template>
<script setup>
import Digit from "./Digit.vue";
import {  watchEffect, watch, onMounted, ref } from "vue";
import { useTimer, useStopwatch   } from 'vue-timer-hook';

const props = defineProps({
  pause:{
    type:Boolean,
    default: false
  },
  time: {
    type: Number,
    default: 0
  },
  number:String
});

const emit = defineEmits(['start', 'pause', 'endattendance'])


const autoStart = true;
const timer = useStopwatch(props.time, autoStart);

const time = new Date();
time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
const timerDown = useTimer(time, false);

function pause(){
  timer.pause();
  timerDown.start();
}

onMounted(() => {

  watchEffect(async () => {
    if(timerDown.isExpired.value) {
        console.warn('IsExpired');
        emit('endattendance', props.number)
    }

    if(props.pause){
      pause();
    }
   
  });
})
</script>
<style scoped>
.pos-timer-container{
  color:red
}
</style>