<template>
  <div class="container q-pt-xl">
    <div class="flex justify-center">
      <div id="call">
        <div class="flex justify-center">
          <q-input filled v-model="custumer" :dense="dense" />
        </div>
        <div class="flex items-center q-gutter-xl q-pt-md">
          <q-btn @click="addNumber('1')" round color="white" text-color="black">
            1
          </q-btn>
          <q-btn @click="addNumber('2')" round color="white" text-color="black">
            2
          </q-btn>
          <q-btn @click="addNumber('3')" round color="white" text-color="black">
            3
          </q-btn>
        </div>
        <div class="flex items-center q-gutter-xl q-pt-md">
          <q-btn @click="addNumber('4')" round color="white" text-color="black">
            4
          </q-btn>
          <q-btn @click="addNumber('5')" round color="white" text-color="black">
            5
          </q-btn>
          <q-btn @click="addNumber('6')" round color="white" text-color="black">
            6
          </q-btn>
        </div>
        <div class="flex items-center q-gutter-xl q-pt-md">
          <q-btn @click="addNumber('7')" round color="white" text-color="black">
            7
          </q-btn>
          <q-btn @click="addNumber('8')" round color="white" text-color="black">
            8
          </q-btn>
          <q-btn @click="addNumber('9')" round color="white" text-color="black">
            9
          </q-btn>
        </div>
      
        <div class="flex items-center q-gutter-xl q-pt-md">
          <q-btn @click="addNumber('*')" round color="white" text-color="black">
            *
          </q-btn>
          <q-btn @click="addNumber('0')" round color="white" text-color="black">
            0
          </q-btn>
          <q-btn @click="addNumber('#')" round color="white" text-color="black">
            #
          </q-btn>
        </div>
        <div class="flex justify-end q-gutter-xl q-pt-md">
          <q-btn @click="call" round color="green" icon="call" />
          <q-btn @click="deleteNumber" round color="white" font-size="10" text-color="dark" icon="backspace" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";

const emit = defineEmits(["call"]);

let custumer = ref("");

function addNumber(numero) {
  custumer.value = custumer.value + numero
}

function deleteNumber() {
  custumer.value = custumer.value.slice(0, -1)
}

function call(){
  if(validateNumber){
    emit('call', custumer.value);
  }
}

function validateNumber(){
  return true;
}

</script>
<style scoped>

</style>