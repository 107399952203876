<template>
  <div>
    <div class="flex justify-center align-center q-pt-md">
      <div v-if="selectedCall.establishing || selectedCall.pos_attendance">
      
        <q-avatar  v-if="selectedCall.pos_attendance"
         size="100px" font-size="80px" color="grey-7"
          text-color="grey" icon="person" >
          <q-icon  style="position: absolute;"
           color="red" size="40px" name="close"/>
          </q-avatar>

        <q-avatar v-else size="100px" font-size="52px" color="teal"
          text-color="white" icon="person" />
          
        </div>
            
        <div v-else>
          <q-spinner
              color="teal"
              size="6em"
              :thickness="4"
            />
       </div>
    </div>
    <div class="flex justify-center align-center q-pt-sm color-black">
      {{ selectedCall.name }}
    </div>
    <div class="flex justify-center align-center color-black">
      {{ selectedCall.number }}
    </div>
    <div v-if="selectedCall.pos_attendance">
      <div class="flex justify-center align-center q-pt-sm color-black">
          {{ selectedCall.name }} saiu da chamada
        </div>
        <q-separator class="q-mt-lg"/>
        <div class="flex justify-center align-center color-black" style="margin-top: -20px;">
          <q-btn @click="emit('endattendance', selectedCall.number)" round color="red" icon="call_end" >
                <q-tooltip>
                  Finalizar Atendimento
                </q-tooltip>
              </q-btn>
        </div>
    </div>
    
    <div v-else class="flex justify-center align-center q-pt-sm">
      <div class="card">
        <div class="container-fluid q-pt-xs">
          <div class="flex justify-center items-center" style="gap: 4%;">
            <q-btn @click="emit('dialpad')" flat round color="white" icon="dialpad" >
              <q-tooltip>
                Teclado
              </q-tooltip>
            </q-btn>
            <q-btn flat round color="white" icon="phone_forwarded" >  
              <q-tooltip>
                Transferir Chamada
              </q-tooltip>
              <q-popup-proxy :offset="[10, 10]">
                <q-card flat bordered>
                  <q-card-section class="q-pt-xs">
                    <div class="text-h6">Informe um Ramal para Transferir</div>
                  </q-card-section>
                  <q-card-section class="q-pt-xs">
                      <q-input outlined v-model="number_transfer"  />
                  </q-card-section>
                  <q-card-section class="q-pt-xs flex justify-center items-center">
                      <q-btn @click="emit('transfer', selectedCall.number, number_transfer)" color="secondary" icon-right="phone_forwarded" label="Transferir" />
                  </q-card-section>
                </q-card>
              </q-popup-proxy>
            </q-btn>
            <q-btn flat round color="red" icon="call" @click="emit('hangup', selectedCall.number)" >
              <q-tooltip>
                Desligar Chamada
              </q-tooltip>
            </q-btn>

            <q-btn flat round  @click="mute" v-if="selectedCall.mute" color="red" icon="mic_off" >
              <q-tooltip>
                Remover Mute
              </q-tooltip>
            </q-btn>
            <q-btn flat round @click="mute" v-else color="white" icon="mic" >
              <q-tooltip>
                Colocar em Mute
              </q-tooltip>
            </q-btn>
            
            <q-btn flat round @click="hold" v-if="callPause" color="red" icon="phone_paused" >
              <q-tooltip>
                Remove de Espera
              </q-tooltip>
            </q-btn>
            <q-btn flat round @click="hold" v-else color="white" icon="phone_paused" > 
              <q-tooltip>
                Adicionar Em Espera
              </q-tooltip>
            </q-btn>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script setup>
import { ref } from 'vue'

const props = defineProps({ selectedCall: Object});
const emit = defineEmits(['hangup', 'endattendance', 'dialpad', 'mute', 'hold']);

let callPause = ref(false)

let number_transfer = ref("");

async function mute(){
  props.selectedCall.mute = !props.selectedCall.mute
  emit('mute', props.selectedCall.number, !props.selectedCall.mute);
}

function hold(){
  callPause.value = !callPause.value
  emit('hold', props.selectedCall.number);
}

</script>
<style scoped>
.card {
  height: 50px;
  width: 70%;
  background-color: #121630;
  border-radius: 10px;
}

.color-black{
  color:black
}
</style>