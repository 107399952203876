import http from '../http-common'

export default class ContactServices {

    async getAll(company_id){
        let res = await http.get(`v1/contact/for-company/${company_id}`);
        return res.data;
    }

    async save(data){
        let res = await http.post(`v1/contact`, data);
        return res.data;
    }
    
    async edit(data){
        let res = await http.put(`v1/contact/${data.id_contato}`, data);
        return res.data;
    }

    async search(id){
        let res = await http.get(`v1/contact/${id}`);
        return res.data;
    }

    async disable(id){
        let res = await http.delete(`v1/contact/${id}`);
        return res.data;
    }

    async enable(id){
        let res = await http.post(`v1/contact/${id}`);
        return res.data;
    }

    async getAddress(cep){
        let res = await http.get(`https://viacep.com.br/ws/${cep}/json/`);
        return res.data;
    }

    async getAllTypesCustomFields(){
        let res = await http.get(`v1/contact/custom-fields/types`);
        return res.data;
    }

    async getAllCustomFields(company_id){
        let res = await http.get(`v1/contact/custom-fields/${company_id}`);
        return res.data;
    }

    async saveCustomField(data){
        let res = await http.post(`v1/contact/custom-fields`, data);
        return res.data;
    }
    
    async editCustomField(data){
        let res = await http.put(`v1/contact/custom-fields/${data.id_campo_personalizado}`, data);
        return res.data;
    }

    async searchCustomField(id){
        let res = await http.get(`v1/contact/custom-fields/search/${id}`);
        return res.data;
    }

    async disableCustomField(id){
        let res = await http.delete(`v1/contact/custom-fields/${id}`);
        return res.data;
    }

    async enableCustomField(id){
        let res = await http.post(`v1/contact/custom-fields/${id}`);
        return res.data;
    }
}