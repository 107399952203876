<template>

      <div class="layout-drag-drop">
        <CallReceived :show-dialog="call_received" :number="number_received" @show-call="showCall" />
        <CallDragAndDrop 
          :calls="calls"
          :out_screen="config.out_screen"
          @hangup="(number) => hangup(number)"
          @endattendance="(number) => endattendance(number)"
          @mute="(number, state) => mute(number, state)"
          @hold="(number) => hold(number)"
          @transfer="(number, number_transfer) => transfer(number, number_transfer)"
        />
      </div>
  
    <audio id="mediaElement" hidden controls></audio>
    <audio hidden ref="audio_init_call" id="audio_init_call" preload="preload" loop="loop">
      <source src="../../assets/web-rtc/call_phone.mp3" type="audio/mpeg">
    </audio>
    <audio hidden ref="audio_busy_call" preload="preload">
      <source src="../../assets/web-rtc/busy_call.mp3" type="audio/mpeg">
    </audio>
  
    <q-card v-show="!config.out_screen" class="my-card text-white menuMain card-color" style="border-radius: 3%;    width: 225px;">
      <q-card-section style="background: #2F3E6A;">
          <div class="flex items-center justify-between">
          <div class="text-call">
              <q-icon name="call" />
              Chamadas
          </div>
          <q-btn v-if="config.outbound" class="q-ml-sm" @click="callNew" round dense color="white" text-color="black" icon="add"></q-btn>
          </div>
      </q-card-section>
      <q-separator dark />
      <q-card-actions style="padding: 0px !important;">
          <div style="max-width: 350px;">
          <q-list separator>
              <q-slide-item>
              <q-item v-for="(call, index) in calls" :key="index"
                  :class="call_selected != null ? call.id == call_selected.id ? 'active-card-phone' : 'card-phone' : 'card-phone'"
                  @click="muteAnotheCall(call.number)">
                  <q-item-section top avatar @click="getCall(call.id)">
                  <q-avatar class="q-mt-sm" color="teal" text-color="white">
                      <q-icon size="sm" name="person" />
                  </q-avatar>
                  </q-item-section>

                  <q-item-section @click="getCall(call.id)">
                  <q-item-label lines="1">{{ call.name }}</q-item-label>
                  <q-item-label lines="1"><q-icon name="phone" size="xs" /> {{ call.number }}</q-item-label>
                  <q-item-label><stopwatch :pause="call.pos_attendance"
                  :number="call.number"
                  :time="call.time" /></q-item-label>
                  </q-item-section>
              </q-item>
              </q-slide-item>
          </q-list>
          </div>
      </q-card-actions>
    </q-card>

    <q-card v-show="!config.out_screen"  v-if="data_call" class="my-card text-white card-color" style="width: 80vh; border-radius: 3%;">
      <q-card-section style="background: #2F3E6A;">
          <div class="flex items-center justify-between">
          <div class="text-call">
              <span class="material-icons" @click="data_call = false">
              arrow_back_ios
              </span>
              Dados da chamada
          </div>
          </div>
      </q-card-section>
      <q-separator dark />
      <q-card-section>
          <CallData :selectedCall="call_selected"
          @hangup="(number) => hangup(number)"
          @endattendance="(number) => endattendance(number)"
          @dialpad="dialpad"
          @mute="(number, state) => mute(number, state)"
          @hold="(number) => hold(number)"
          @transfer="(number, number_transfer) => transfer(number, number_transfer)"
          />
      </q-card-section>
    </q-card>
    
    <q-card v-show="!config.out_screen"  v-if="new_call" class="my-card text-white card-color" 
    style="width: 55vh; border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;box-shadow: 6px 2px 6px 1px rgb(0 0 0 / 12%);">
    <q-card-section style="background: #2F3E6A;border-radius: 0;">
        <div class="flex items-center justify-between">
        <div class="text-call">
            <span class="material-icons" @click="new_call = false">
            arrow_back_ios
            </span>
            Nova Chamada
        </div>
        </div>
    </q-card-section>
    <q-separator dark />
    <q-card-section>
        <PhoneMe @call="(number) => initCall(number)" />
    </q-card-section>
    </q-card>

</template>

<script setup>
import { ref, onMounted } from 'vue'
import { SessionState } from "sip.js";
/*Componentes*/
import PhoneMe from './PhoneMe.vue'
import CallData from './CallData.vue'
import CallDragAndDrop from './CallDragAndDrop.vue';
import Stopwatch from "./StopWatch.vue";
import CallReceived from './CallReceived.vue';
/*Provider*/
import PabxProvider from "./provider/PabxProvider";

/*Audios*/
const audio_init_call = ref();
const audio_busy_call = ref();

/*Variables Layout*/
let new_call = ref(false)
let data_call = ref(false)
let call_received = ref(false);
let number_received = ref("");

/*Dados da Chamada */
let call_selected = ref();
let calls = ref([]);

/*Emits e Props */
const emit = defineEmits(['init_call', 'terminated_call', 'received_call', 'show_call']);
const props = defineProps({config:Object});

/* Servidor PABX */
let _pabxProvider = new PabxProvider(props.config.server, props.config.ramal, props.config.password, onInvite);

function onInvite(invitation) {
  let customer = invitation._assertedIdentity.uri.normal.user;
  _pabxProvider.addInviter(customer, invitation);
  invitation.stateChange.addListener((state) => {
      switch (state) {
          case SessionState.Initial:
            break;
            case SessionState.Establishing:
              initCall(customer, false);
              break;  
            case SessionState.Established:
              established(customer);
              _pabxProvider.setupRemoteMedia(invitation);
              break;
          case SessionState.Terminating:
              break;
          case SessionState.Terminated:
              terminated(customer);
              _pabxProvider.cleanupMedia();
              break;
          default:
              throw new Error("Unknown session state.");
      }
  });
    
  if(props.config.inbound_automatic){
    invitation.accept();
  }



  number_received.value = customer;
  call_received.value = true;
}

function showCall(){
  call_received.value = false;
  emit('show_call');
}

function callNew() {
  data_call.value = false
  new_call.value = true
}


function initCall(number, active = true){
  let indexCall = getIndexCall(number);

  if(indexCall < 0){
    calls.value.push({
      id: number,
      name: 'Mobile Number, Brazil',
      number: number,
      establishing: false,
      pos_attendance: false,
      mute: false
    });

    if(active){
      _pabxProvider.call(number, establishing, established, terminated);
    }
  }

  getCall(number);

  emit("init_call", number);
}

function getCall(idCall) {
  new_call.value = false
  data_call.value = true
  call_selected.value = calls.value.find(element => element.id == idCall)
}


function establishing(customer){
  audio_init_call.value.play();

  let indexCall = getIndexCall(customer);

  calls.value[indexCall].establishing = true;

  if(calls.value[indexCall].id == call_selected.value.id){
    call_selected.value.establishing = true;
  }
}

function established(customer){
  audio_init_call.value.pause();
  
  let indexCall = getIndexCall(customer);

  calls.value[indexCall].establishing = true;
}

function terminated(customer){
  audio_init_call.value.pause();
  audio_busy_call.value.play();

  let indexCall = getIndexCall(customer);

  calls.value[indexCall].pos_attendance = true;

  if(calls.value[indexCall].id == call_selected.value.id){
    call_selected.value.pos_attendance = true;
  }

  emit("terminated_call", customer);
}

function hangup(customer){
  _pabxProvider.hangup(customer);
}

function muteAnotheCall(customercall_selected){
    for (let index = 0; index < calls.value.length; index++) {
        if(calls.value[index].number != customer){
            calls.value[index].mute = true;
            mute(calls.value[index].number, false);
        }
    }
}

function mute(customer, state){
  _pabxProvider.mute(customer, state);
}

function hold(customer){
  _pabxProvider.hold(customer);
}

function endattendance(customer){
  audio_busy_call.value.pause();
  let indexCall = getIndexCall(customer);
  calls.value.splice(indexCall, 1);
  _hiddenDialpadAnddataCall();
}

function transfer(customer, number_transfer){
  let isTransfer = _pabxProvider.transfer(customer, number_transfer);
  if(isTransfer){
    let indexCall = getIndexCall(customer);
    calls.value.splice(indexCall, 1);
    _hiddenDialpadAnddataCall();
  }
}

function _hiddenDialpadAnddataCall(){
  new_call.value = false
  data_call.value = false;
}

function dialpad(){
  new_call.value = true
  data_call.value = false;
}

function getIndexCall(number){
  let indexCall = calls.value.findIndex(value => {
    return value.number == number
  });
  return indexCall;
}

onMounted(async () => {
  await _pabxProvider.connect();
})

</script>

<style scoped>
.text-call {
  font-size: 19px;
}

.text-small {
  font-size: 12px;
  font-weight: bolder;
}

.text-task {
  font-size: 15px;
}

.h-25 {
  height: 35vh;
}

.w-25 {
  max-width: 35vh;
}

.menuMain {
  height: 100vh !important;
}


.text-inf {
  color: azure;
}

.linha-vertical {
  height: 30px;
  border-left: 1px solid;
}

.card-color {
  box-shadow: 4px 0 4px rgb(0 0 0 / 12%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(133.17% 32.16% at 59.58% 51.43%, rgba(237, 241, 250, 0.56) 0%, rgba(237, 241, 250, 0.35) 100%);
}

.information {
  height: 100vh;
  width: 100%;
}
.card-phone{
  background-color: #98C4CD; 
  border-bottom: 1px solid gray;
}
.active-card-phone{
  background-color: rgb(18 22 48);
  border-bottom: 1px solid gray;
}
.layout-drag-drop{
  position: absolute;
  width: 100% !important;
  height: 100%;
  display: flex;
  
}

</style>
