<template>
    <audio hidden ref="audio_new_message">
        <source src="../../assets/audio/audio_message.mp3" type="audio/mpeg">
    </audio>
    <div class="col-12">
        <div class="col-12" v-show="showListContact">
            <q-list bordered>
                <q-item v-ripple dense>
                    <q-item-section>
                        <div class="flex row col-12">
                            
                            <q-input class="col-12 input-search" borderless placeholder="Buscar pelo nome"
                                v-model="filter_input_contact" @change="filterUsers">
                        
                                    <template v-if="filter_input_contact" v-slot:append>
                                    <q-icon name="cancel" @click.stop.prevent="filter_input_contact = ''"
                                        class="cursor-pointer" />
                                   </template>
                            </q-input>
                         </div>
                    </q-item-section>
                </q-item>

                <q-separator />
                <div v-if="filter_input_contact != ''" style="height: 19.8rem;overflow-y: auto;">
                    <div class="col-12">
                        <div v-for="(user, index) in filterUsers()" :key="user.id_usuario">
                            <q-item class="q-mb-sm q-px-sm" @click="openInternalChat(user, index)" clickable v-ripple>
                                <q-item-section avatar>
                                    <div class="flex row justify-start">                                                
                                        <q-badge  rounded :color="user.online ? 'green' : 'grey'" />
                                    </div>
                                </q-item-section>

                                <q-item-section><q-item-label lines="1">{{ user.nome }}</q-item-label></q-item-section>
                                       
                            </q-item>
                        </div>

                    </div>
                </div>

            <div v-else style="height: 19.8rem;overflow-y: auto;">
                <div v-for="(group, index) in groups" :key="index">

                    <template v-if="hidegroupAgenteChat()">
                        
                        <template v-if="userType === 'Admin'">
                            <q-expansion-item expand-separator :label="group.name"
                             :caption="`${group.users.length} onlines - ${group.usersOffline.length} offlines`">
                        <template v-slot:header>
                            <q-item-section>
                                <q-item-label>{{ group.name }}</q-item-label>
                                <q-item-label caption lines="1">{{group.users.length}} onlines - {{group.usersOffline.length}} offlines</q-item-label>
                            </q-item-section>
                            <q-item-section side> 
                                <div v-show="group.isnewmessage">
                                    <q-badge class="q-mr-md" rounded color="orange" :label="group.qt_nao_lidas"/>
                                </div>
                       </q-item-section>
                        </template>
                        <q-card>
                            <q-card-section>
                                <div class="col-12">
                                    <q-item-label  class="q-pa-none" header v-show="group.users.length > 0">Online</q-item-label>
                                    <q-item class="q-pa-none" @click="openInternalChat(user, index, indexUser, 'online')" clickable v-ripple
                                        v-for="(user, indexUser) in group.users" :key="indexUser">
                                        <q-item-section avatar>
                                            <div class="flex row justify-start">                                                
                                                <q-badge rounded color="green" />
                                            </div>
                                        </q-item-section>

                                        <q-item-section><q-item-label lines="1">{{ user.nome }}</q-item-label></q-item-section>
                                        <q-item-section side> 
                                            <div v-show="user.isnewmessage" class="flex row justify-end">
                                                <q-badge class="q-mr-md" rounded color="orange" :label="user.qt_nao_lidas"/>
                                            </div>
                                        </q-item-section>
                                    </q-item>

                                  
                                    <q-separator  class="q-pa-none" spaced v-show="group.users.length > 0 && group.usersOffline.length > 0"/>
                                    <q-item-label  class="q-pa-none" header v-show="group.usersOffline.length > 0">Offline</q-item-label>
                                    <q-item class="q-pa-none" @click="openInternalChat(user, index, indexUser, 'offline')" clickable v-ripple
                                        v-for="(user, indexUser) in group.usersOffline" :key="indexUser">
                                        <q-item-section avatar>                         
                                                <q-badge rounded color="grey" />
                                        </q-item-section>

                                        <q-item-section><q-item-label lines="1">{{ user.nome }}</q-item-label></q-item-section>
                                        <q-item-section side> 
                                            <div v-show="user.isnewmessage">
                                                <q-badge class="q-mr-md" rounded color="orange" :label="user.qt_nao_lidas"/>
                                            </div>
                                        </q-item-section>
                                    </q-item>

                                </div>
                            </q-card-section>
                        </q-card>
                        </q-expansion-item>
                        </template>
                        <template v-else-if="group.name !== 'Agente'">
                       <q-expansion-item expand-separator :label="group.name"
                             :caption="`${group.users.length} onlines - ${group.usersOffline.length} offlines`">
                        <template v-slot:header>
                            <q-item-section>
                                <q-item-label>{{ group.name }}</q-item-label>
                                <q-item-label caption lines="1">{{group.users.length}} onlines - {{group.usersOffline.length}} offlines</q-item-label>
                            </q-item-section>
                            <q-item-section side> 
                                <div v-show="group.isnewmessage">
                                    <q-badge class="q-mr-md" rounded color="orange" :label="group.qt_nao_lidas"/>
                                </div>
                       </q-item-section>
                        </template>
                        <q-card>
                            <q-card-section>
                                <div class="col-12">
                                    <q-item-label  class="q-pa-none" header v-show="group.users.length > 0">Online</q-item-label>
                                    <q-item class="q-pa-none" @click="openInternalChat(user, index, indexUser, 'online')" clickable v-ripple
                                        v-for="(user, indexUser) in group.users" :key="indexUser">
                                        <q-item-section avatar>
                                            <div class="flex row justify-start">                                                
                                                <q-badge rounded color="green" />
                                            </div>
                                        </q-item-section>

                                        <q-item-section><q-item-label lines="1">{{ user.nome }}</q-item-label></q-item-section>
                                        <q-item-section side> 
                                            <div v-show="user.isnewmessage" class="flex row justify-end">
                                                <q-badge class="q-mr-md" rounded color="orange" :label="user.qt_nao_lidas"/>
                                            </div>
                                        </q-item-section>
                                    </q-item>

                                  
                                    <q-separator  class="q-pa-none" spaced v-show="group.users.length > 0 && group.usersOffline.length > 0"/>
                                    <q-item-label  class="q-pa-none" header v-show="group.usersOffline.length > 0">Offline</q-item-label>
                                    <q-item class="q-pa-none" @click="openInternalChat(user, index, indexUser, 'offline')" clickable v-ripple
                                        v-for="(user, indexUser) in group.usersOffline" :key="indexUser">
                                        <q-item-section avatar>                         
                                                <q-badge rounded color="grey" />
                                        </q-item-section>

                                        <q-item-section><q-item-label lines="1">{{ user.nome }}</q-item-label></q-item-section>
                                        <q-item-section side> 
                                            <div v-show="user.isnewmessage">
                                                <q-badge class="q-mr-md" rounded color="orange" :label="user.qt_nao_lidas"/>
                                            </div>
                                        </q-item-section>
                                    </q-item>

                                </div>
                            </q-card-section>
                        </q-card>
                        </q-expansion-item>
                    </template>


                    </template>

                    <template v-else-if="!hidegroupAgenteChat()">
                        <q-expansion-item expand-separator :label="group.name"
                             :caption="`${group.users.length} onlines - ${group.usersOffline.length} offlines`">
                        <template v-slot:header>
                            <q-item-section>
                                <q-item-label>{{ group.name }}</q-item-label>
                                <q-item-label caption lines="1">{{group.users.length}} onlines - {{group.usersOffline.length}} offlines</q-item-label>
                            </q-item-section>
                            <q-item-section side> 
                                <div v-show="group.isnewmessage">
                                    <q-badge class="q-mr-md" rounded color="orange" :label="group.qt_nao_lidas"/>
                                </div>
                       </q-item-section>
                        </template>
                        <q-card>
                            <q-card-section>
                                <div class="col-12">
                                    <q-item-label  class="q-pa-none" header v-show="group.users.length > 0">Online</q-item-label>
                                    <q-item class="q-pa-none" @click="openInternalChat(user, index, indexUser, 'online')" clickable v-ripple
                                        v-for="(user, indexUser) in group.users" :key="indexUser">
                                        <q-item-section avatar>
                                            <div class="flex row justify-start">                                                
                                                <q-badge rounded color="green" />
                                            </div>
                                        </q-item-section>

                                        <q-item-section><q-item-label lines="1">{{ user.nome }}</q-item-label></q-item-section>
                                        <q-item-section side> 
                                            <div v-show="user.isnewmessage" class="flex row justify-end">
                                                <q-badge class="q-mr-md" rounded color="orange" :label="user.qt_nao_lidas"/>
                                            </div>
                                        </q-item-section>
                                    </q-item>

                                  
                                    <q-separator  class="q-pa-none" spaced v-show="group.users.length > 0 && group.usersOffline.length > 0"/>
                                    <q-item-label  class="q-pa-none" header v-show="group.usersOffline.length > 0">Offline</q-item-label>
                                    <q-item class="q-pa-none" @click="openInternalChat(user, index, indexUser, 'offline')" clickable v-ripple
                                        v-for="(user, indexUser) in group.usersOffline" :key="indexUser">
                                        <q-item-section avatar>                         
                                                <q-badge rounded color="grey" />
                                        </q-item-section>

                                        <q-item-section><q-item-label lines="1">{{ user.nome }}</q-item-label></q-item-section>
                                        <q-item-section side> 
                                            <div v-show="user.isnewmessage">
                                                <q-badge class="q-mr-md" rounded color="orange" :label="user.qt_nao_lidas"/>
                                            </div>
                                        </q-item-section>
                                    </q-item>

                                </div>
                            </q-card-section>
                        </q-card>
                        </q-expansion-item>
                    
                    </template>

                                   
                    </div>
                </div>

            </q-list>
        </div>
        <div class="col-12" v-show="!showListContact">
            <div class="col-12 flex row q-pa-sm">
                <q-icon name="arrow_back" class="cursor-pointer" size="20px" @click="closeInternalChat" />
                <div class="text-center col-11">
                    <label>{{ sender.nome }}</label>
                </div>
            </div>

            <div class="card-content-chat-body">
                <div class="screen-message-chat">
                    <q-infinite-scroll @load="onLoadMessages" id="scrollAreaInternalChat" ref="scrollAreaRef"
                        :offset="5" reverse class="scroll q-pr-sm">
                        <template v-slot:loading>
                            <div class="row justify-center q-my-md">
                                <q-spinner-dots color="primary" size="2em" />
                            </div>
                        </template>
                        <div v-for="message in messages" :key="message.id_mensagem_ci">
        
                                <q-chat-message :text="[message.mensagem_chat_interno]" size="6" :stamp="formatDate(message.mensagem_data)"
                                v-if="message.id_usuario_remetente == sender.id_usuario" bg-color="container-answer-menssage-chat">
                          
                                <div v-if="message.filepath != null && message.filepath != ''">
                                <a :href="urlAwsBucket + message.filepath" target="_blank"
                                    download>{{ message.filepath }}</a>
                                </div>
                                <label class="text-subtitle2" v-else>
                                <span v-html="message.mensagem_chat_interno.replace(/(?:\r\n|\r|\n)/g, '<br>')"/>
                                </label>
                            </q-chat-message>
                                <q-chat-message size="6" v-else :text="[message.mensagem_chat_interno]"
                                  :stamp="formatDate(message.mensagem_data)" sent
                                  bg-color="container-send-menssage-chat">

                                  <div v-if="message.filepath != null && message.filepath != ''">
                                     <a :href="urlAwsBucket + message.filepath" target="_blank"
                                        download>{{ message.mensagem_chat_interno }}</a>
                                    </div>

                                    <label class="text-subtitle2" v-else>
                                    <span v-html="message.mensagem_chat_interno.replace(/(?:\r\n|\r|\n)/g, '<br>')"/>
                                    </label>

                                </q-chat-message>
                        </div>

                        <div class="flex row justify-center">
                            <q-circular-progress
                                v-if="showProgressCircularFile"
                                show-value
                                class="text-blue q-ma-md flex justify-end align-end"
                                :value="loadingFileValue"
                                size="50px"
                                color="primary"
                            />
                            </div>
                    </q-infinite-scroll>
                </div>
            </div>

            <div class="content-input-message-send q-px-sm">
                <q-file v-model="file" v-show="false" @update:model-value="updateFiles()" ref="refFileInput"
                      disabled/>
                <q-input outlined bottom-slots type="text" @keyup.enter="sendMessage" bg-color="white" v-model="text"
                    dense>
                    <template v-slot:prepend>
                        <q-btn @click="sendEventAttachment" round dense flat color="black" icon="attach_file" />
                    </template>
                    <template v-slot:after>
                        <q-btn round dense flat color="black" icon="send" @click="sendMessage" />
                    </template>
                </q-input>
            </div>
        </div>

    </div>
</template>
<script setup>
import { ref, onMounted, inject } from 'vue';
import { useQuasar } from 'quasar';
import { useStore } from 'vuex';
import InternalChatServices from '@/services/InternalChatServices';
import { ENV } from "@/utils/env";
import CompanyServices from '@/services/CompanyServices';

let emit = defineEmits(["isnewmessage"]);
let loadingFileValue = ref(0);
let showProgressCircularFile = ref(false);

let urlAwsBucket = ENV.URL_AWS_BUCKET;
const audio_new_message = ref();
const $q = useQuasar();
const store = useStore();
let userType = store.state.userData.papel;

const _internalChatService = new InternalChatServices();
const socket_chat = ref(inject("socket_chat"));
let _containsMessageInHistory = ref(true);
let showListContact = ref(true);
let groups = ref([]);

let filter_input_contact = ref("");
let filter_input_contact_without_agente = ref("");
let sender = ref({});
let text = ref("");
let file = ref(null);
let messages = ref([]);
let _quantityMessage = ref(10);
let _pageMessage = ref(1);
let refFileInput = ref(null);
const _companyServices = new CompanyServices();

async function updateFiles() {
    
    if (file.value == null) return;

    showProgressCircularFile.value = true;
    
    _scroll();

    const formData = _getFormDataForSendMessage();

    await _internalChatService.sendMessage(formData, loadingFile).then(response => {
        showProgressCircularFile.value = false;
    }).catch((err) => {
        
        $q.notify({
          message: err.response.data,
          color: "red-8",
          position: "top",
        });
        showProgressCircularFile.value = false;
      });;

    _scroll()

    file.value = null;
}

function loadingFile(value) {
  loadingFileValue.value = value;

}

let sendEventAttachment = () => {
  refFileInput.value.pickFiles();
};


async function _getAllContactInternalChat() {
    _internalChatService.getAll().then(response => {
        groups.value = response;
        _sinalizeNewMessages();
    })
}


function filterUsersWithoutAgente() {
    let users = [];
    groups.value.forEach(group => {

        if(group.nome !== 'Agentes'){
        group.users.concat(group.usersOffline).forEach(g => {
            users.push(g);
        });;
    }
});

       return users.filter(user => {
        return user.nome.toLowerCase().includes(filter_input_contact_without_agente.value.toLowerCase())
    })
}

function openInternalChat(user, index, indexUser, type) {
    messages.value = []
    showListContact.value = false;
    sender.value = user
    _pageMessage.value = 1;

    _emptyIconNewMessage(type, index, indexUser)
    _getHistoryMessage()
}

function _emptyIconNewMessage(type, index, indexUser){

    if(type == "online"){
        groups.value[index].users[indexUser].isnewmessage = false;
        groups.value[index].qt_nao_lidas =  groups.value[index].qt_nao_lidas - groups.value[index].users[indexUser].qt_nao_lidas;
        groups.value[index].users[indexUser].qt_nao_lidas = 0;
    }else{
        groups.value[index].usersOffline[indexUser].isnewmessage = false;
        groups.value[index].qt_nao_lidas =  groups.value[index].qt_nao_lidas - groups.value[index].usersOffline[indexUser].qt_nao_lidas;
        groups.value[index].usersOffline[indexUser].qt_nao_lidas = 0;
    }

    if(groups.value[index].qt_nao_lidas == 0){
        groups.value[index].isnewmessage = false;
    }

    _verifyUnreadMessagesFromGroups();
}

function _verifyUnreadMessagesFromGroups(){
    let noUnreadMessages = 0; 
    groups.value.forEach((group)=>{
        if(group.isnewmessage){
            emit("isnewmessage", group.isnewmessage)
            return
        }else{
            noUnreadMessages = noUnreadMessages + 1   
        }

        if(noUnreadMessages == groups.value.length){
            emit("isnewmessage", false)
        }
    })
}

async function _getHistoryMessage() {
    await _internalChatService.getHistoryMessage(store.state.userData.id_usuario, sender.value.id_usuario, _pageMessage.value, _quantityMessage.value).then(response => {
        messages.value = response
        _scroll();
    })
}

function closeInternalChat() {
    showListContact.value = true;
    sender.value = {};
}

function formatDate(date){
    return new Date(date).toLocaleString() == 'Invalid Date' ?  date : new Date(date).toLocaleString();
}


function _scroll() {
    let sizeScreen = document.getElementById("scrollAreaInternalChat").offsetHeight * 12;
    setTimeout(() => {
        document.getElementById("scrollAreaInternalChat").scrollBy({
            top: sizeScreen,
            left: 0,
            behavior: "smooth"
        });
    }, 100);
}

async function onLoadMessages(index, done) {
    const CONTAINS_MESSAGE = messages.value.length > 0;
    if (CONTAINS_MESSAGE) {
        if (_containsMessageInHistory.value) {
            _pageMessage.value++;
            let data = await _internalChatService.getHistoryMessage(store.state.userData.id_usuario, sender.value.id_usuario, _pageMessage.value, _quantityMessage.value);
            _containsMessageInHistory.value = data.length > 0;
            data.reverse().forEach(message => {
                messages.value.unshift(message);
            });
        } else {
            _scroll();
        }
    } else {
        _scroll();
    }
    done();
}

async function sendMessage() {
    if (text.value == "") return;

    const formData = _getFormDataForSendMessage();

    _internalChatService.sendMessage(formData, loadingFile);

    text.value = "";

    _scroll()
}

function _getFormDataForSendMessage() {
    const formData = new FormData();
    formData.append("Mensagem", text.value);
    formData.append("Destinatario", sender.value.id_usuario);
    formData.append("File", file.value);
    return formData;
}

function _sinalizeNewMessages(){
    groups.value.forEach((group)=>{
        if(group.isnewmessage){
            emit('isnewmessage',group.isnewmessage)
        }
    })
}

onMounted(async () => {
    await _getAllContactInternalChat();
    _getNewMessage();
    _addUserToInternalChat();
    _removeUserToInternalChat();
    enableParameters();
});


function _getNewMessage() {
    socket_chat.value.on("ReceiveMessageChatInternal", (message) => {
        if(sender.value.id_usuario == message.id_usuario_remetente || 
           sender.value.id_usuario == message.id_usuario_destinatario){
            messages.value.push(message);
            if(message.id_usuario_remetente == store.state.userData.id_usuario){
                _internalChatService.readMessage(message.id_usuario_remetente);
            }
            _scroll();

        }else{
            _updateNewMessage(message.id_usuario_remetente);  
            audio_new_message.value.play(); 
        }
    });
}

function _updateNewMessage(id_usuario){
    groups.value.forEach((group, index) => {
        let userOnline = group.users.findIndex(userFilter => {
            return userFilter.id_usuario == id_usuario
        });

        if(userOnline >= 0){
            groups.value[index].isnewmessage = true;
            groups.value[index].qt_nao_lidas = groups.value[index].qt_nao_lidas == null ? 1 : groups.value[index].qt_nao_lidas + 1;
            groups.value[index].users[userOnline].isnewmessage = true;
            groups.value[index].users[userOnline].qt_nao_lidas = groups.value[index].users[userOnline].qt_nao_lidas == null ? 1 : groups.value[index].users[userOnline].qt_nao_lidas + 1;
        }else{
            let userOffline = group.usersOffline.findIndex(userFilter => {
                return userFilter.id_usuario == id_usuario
            });

            if(userOffline >= 0){
                groups.value[index].isnewmessage = true;
                groups.value[index].qt_nao_lidas = groups.value[index].qt_nao_lidas == null ? 1 : groups.value[index].qt_nao_lidas + 1;
                groups.value[index].usersOffline[userOffline].isnewmessage = true;
                groups.value[index].usersOffline[userOffline].qt_nao_lidas = groups.value[index].usersOffline[userOffline].qt_nao_lidas == null ? 1 : groups.value[index].usersOffline[userOffline].qt_nao_lidas + 1;;
                group.users.push(groups.value[index].usersOffline[userOffline]);
                group.usersOffline.splice(userOffline, 1);
            }
        }
        if(groups.value[index].isnewmessage){
            emit("isnewmessage", groups.value[index].isnewmessage);
        }
    });
}

function _addUserToInternalChat() {
    socket_chat.value.on("NewUserChatInternal", (user) => {
        if (store.state.userData.id_usuario != user.id_usuario) {
            groups.value.forEach(group => {
                let existingUser = group.usersOffline.findIndex(userFilter => {
                    return userFilter.id_usuario == user.id_usuario
                });

                if(existingUser >= 0){
                    group.users.push(user);
                    group.usersOffline.splice(existingUser, 1);
                }
            });
        }
    })
}

function _removeUserToInternalChat() {
    socket_chat.value.on("DisconectUserChatInternal", (user) => {
        if (store.state.userData.id_usuario != user.id_usuario) {
            groups.value.forEach(group => {
                let existingUser = group.users.findIndex(userFilter => {
                    return userFilter.id_usuario == user.id_usuario
                });

                if(existingUser >= 0){
                    group.usersOffline.push(user);
                    group.users.splice(existingUser, 1);
                }
            });
        }
    })
}

let rule = ref([
  {name:"rule_view_agent", value: false, profile: false, profileId:[]}
])

let ruleHideGroupAgenteChat = ref(false);

async function enableParameters(){
  await _companyServices.verifyParameters().then((response) => {
    if(response.length > 0){
      response.map((item)=>{
        rule.value = rule.value.filter((itemRule)=>{
          if(itemRule.name == item.parametro){
            itemRule.value = true;
            itemRule.profile = item.perfil;
            if(item.id_papel != null)
              itemRule.profileId.push(item.id_papel);
          }
          return rule.value;
        });
      });
    }
  });
}

function verifyRule(nameRule){
  let condition = false;
  let objectRule = rule.value.find(item => item.name == nameRule);
  if(objectRule.value){
    condition = (objectRule.profile && objectRule.profileId.length == 0) 
              ? false : (objectRule.profile && objectRule.profileId.length > 0) 
              ? ((objectRule.profileId.includes(store.state.userData.id_papel) ? true : false))
              : objectRule.value;
  }else{
    condition = objectRule.value;
  }
  return condition;
}

function hidegroupAgenteChat (Agente){
    return ruleHideGroupAgenteChat.value = verifyRule("rule_view_agent");
}


function filterUsers() {
    let users = [];
    groups.value.forEach(group => {

        if(hidegroupAgenteChat()){
            if(group.name !== 'Agente'){
            group.users.concat(group.usersOffline).forEach(g => {
            users.push(g);
        });;
        }
       
       
       }else {
     
        group.users.concat(group.usersOffline).forEach(g => {
            users.push(g);
        });;

       }
    
    });
    
       return users.filter(user => {
        return user.nome.toLowerCase().includes(filter_input_contact.value.toLowerCase())
    })
}

</script>
<style lang="scss" scoped>

.q-tab-panel {
    padding: 0px;
}

.list-contacts {
    height: 42vh;
    overflow-y: auto;
}

.input-search {
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none !important;
}

.card-content-chat-body {
    height: 47vh;
    overflow: hidden;
}

.loading-cosmos {
    height: 1.5rem;
    width: 1.5rem;
}

.scroll {
    max-height: 46vh;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>