import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { ENV } from "@/utils/env";
import { useStore } from "vuex";
import { provide } from 'vue'

export default class ChatProvider {

    constructor() {
        this.store = useStore();
        this.connection = new HubConnectionBuilder()
            .withUrl(ENV.BASE_URL_SOCKET, {
                accessTokenFactory: () => localStorage.getItem('token_plataforma_digital')
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();
    }

    start() {
        provide('socket_chat', this.connection)
        this.connection.start()
            .then(() => {
                console.log("Connected");
                this._getMessagesOfCustomer();
                this._newAttendances();
            })
            .catch(e => console.log("Connection failed", e));  
    }

    disconnect(){
        this.connection.stop();
    }

    _getMessagesOfCustomer() {
        this.connection.on("ReceiveMessage", (conversationId, message) => {
            //TODO: EMIT SOM e NOTIFCAÇÂO
        });
    }

    _newAttendances(){
        this.connection.on("NotifyNewAttendances", (conversationId, attendance) => {
            //TESTE
        });
    }
}