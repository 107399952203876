import './styles/quasar.sass'
import lang from 'quasar/lang/pt-BR.js'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import Dialog from 'quasar/src/plugins/dialog/Dialog.js';import Notify from 'quasar/src/plugins/notify/Notify.js';import AppFullscreen from 'quasar/src/plugins/app-fullscreen/AppFullscreen.js';import Cookies from 'quasar/src/plugins/cookies/Cookies.js';import Loading from 'quasar/src/plugins/loading/Loading.js';import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';import BottomSheet from 'quasar/src/plugins/bottom-sheet/BottomSheet.js';;

export default {
    config: {
      loading: {
        message: 'Carregando, por favor aguarde...',
        spinner: QSpinnerHourglass,
      }
    },
    plugins: [Dialog, Notify, AppFullscreen, Cookies, Loading, BottomSheet],
    lang: lang
}