import http from '../http-common'

export default class ReportServices {

    async getFilterAttendancesChat(report) {
        let res = await http.post(`v1/report/filtro-chat`, report);
        return res.data;
    }

    async getFilterAttendancesTicket(report) {
        let res = await http.post(`v1/report/filtro-ticket`, report);
        return res.data;
    }

    async getMessagesAttenadaceChat(attendaceId) {
        let res = await http.get(`v1/report/get-mensagens-atendimento-chat/${attendaceId}`);
        return res.data;
    }

    async getMessagesAttenadaceTicket(attendaceId) {
        let res = await http.get(`v1/report/get-mensagens-atendimento-ticket/${attendaceId}`);
        return res.data;
    }

    async getAllAnexos(id_message) {
        let res = await http.get(`v1/report/get-todos-anexos/${id_message}`);
    }
    
    async getAttachementTicket(messageTicketId) {
        let res = await http.get(`v1/report/get-todos-anexos/${messageTicketId}`);
        return res.data;
    }

    async getReport30Ticket(periodo) {
        let res = await http.get(`v1/report/get-relatorio-30-ticket/${periodo.dataInicio}/${periodo.dataFim}`);
        return res.data;
    }

    async getReportOperadorTicket(periodo) {
        let res = await http.get(`v1/report/get-relatorio-operador-ticket/${periodo.dataInicio}/${periodo.dataFim}`);
        return res.data;
    }

    async getReportTabulacaoTicket(periodo) {
        let res = await http.get(`v1/report/get-relatorio-tabulacao-ticket/${periodo.dataInicio}/${periodo.dataFim}`);
        return res.data;
    }

    async getReportSurveyTicket(periodo) {
        let res = await http.get(`v1/report/get-relatorio-pesquisa-satisfacao/${periodo.dataInicio}/${periodo.dataFim}`);
        return res.data;
    }

    async getReportSurveyChat(periodo) {
        let res = await http.get(`v1/report/get-relatorio-pesquisa-satisfacao-chat/${periodo.dataInicio}/${periodo.dataFim}`);
        return res.data;
    }

    async getReportPause(periodo) {
        let res = await http.get(`v1/report/get-relatorio-tempo-pausa/${periodo.dataInicio}/${periodo.dataFim}`);
        return res.data;
    }

    async getReportTabulacaoChat(periodo){
        let res = await http.get(`v1/report/get-relatorio-tabulacao-chat/${periodo.dataInicio}/${periodo.dataFim}`);
        return res.data;
    }

    async exportMessageChat(idsChat) {
        let res = await http.post(`v1/report/exportar-mensagens-chat`, idsChat);
        return res.data;
    }

    async exportMessageTicket(idsTicket) {
        let res = await http.post(`v1/report/exportar-mensagens-ticket`, idsTicket);
        return res.data;
    }

    async getReportLogin(filtro) {
        let res = await http.post(`v1/report/get-relatorio-login`,filtro);
        return res.data;
    }
}