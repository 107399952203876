import http from '../http-common'

export default class SettingServices {

    constructor(route){
        this.route = route;
    }
    
    async getAll(company_id){
        let res = await http.get(`v1/configuration/${this.route}?companyId=${company_id}`);
        return res.data;
    }

    async getAllActive(company_id){
        let res = await http.get(`v1/configuration/reason-active-state-pause?companyId=${company_id}`);
        return res.data;
    }


    async getAllByChannel(company_id, channel_type){
        let res = await http.get(`v1/configuration/predefined-message-by-channel?companyId=${company_id}&channelType=${channel_type}`)
        return res.data
    }

    async getAllForRoute(route){
        let res = await http.get(`v1/configuration/${this.route}/${route}`);
        return res.data;
    }

    async save(data){
        let res = await http.post(`v1/configuration/${this.route}`, data);
        return res.data;
    }
    
    async edit(id, data){
        let res = await http.put(`v1/configuration/${id}/${this.route}`, data);
        return res.data;
    }

    async search(id){
        let res = await http.get(`v1/configuration/${id}/${this.route}`);
        return res.data;
    }

    async disable(id){
        let res = await http.delete(`v1/configuration/${id}/${this.route}`);
        return res.data;
    }

    async enable(id){
        let res = await http.post(`v1/configuration/${id}/${this.route}`);
        return res.data;
    }
}