import http from '../http-common'

export default class CompanyServices {
    async getAll(id_company){
        let res = await http.get(`v1/company?companyId=${id_company}`);
        return res.data;
    }

    async save(company){
        let res = await http.post(`v1/company`, company);
        return res.data;
    }
    
    async edit(company){
        let res = await http.put(`v1/company/${company.id_empresa}`, company);
        return res.data;
    }

    async search(id_company){
        let res = await http.get(`v1/company/${id_company}`);
        return res.data;
    }

    async updateStatusParameter(id_parameter, status){
        let res = await http.put(`v1/company/update-status-parameter/${id_parameter}?status=${status}`);
        return res.data;
    }

    async updateStatusPaperParameter(id_parameter, id_paper, status){
        let res = await http.put(`v1/company/paper-parameter/${id_parameter}?paperId=${id_paper}&status=${status}`);
        return res.data;
    }

    async getPaperParameter(parameterId){
        let res = await http.get(`v1/company/get-paper-parameter/${parameterId}`);
        return res.data;
    }

    async getParameters(){
        let res = await http.get(`v1/company/get-parameters`);
        return res.data;
    }

    async verifyParameters(){
        let res = await http.get(`v1/company/verify-parameters`);
        return res.data;
    }
}