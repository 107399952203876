import http from '../http-common'

export default class ChatServices {
    async getAllAttendancesForUser() {
        let res = await http.get(`v1/attendant`);
        return res.data;
    }

    async getAllAttendancesForContact(contactId = null, email = null) {
        let res = await http.get(`v1/attendant/get-all-attendance-contact?contactId=${contactId}&email=${email}`);
        return res.data;
    }

    async transferAttendance(attendanceId, queueID) {
        let res = await http.post(`v1/attendant/transfer-attendant/${attendanceId}/${queueID}`);
        return res.data;
    }

    async getMessageForChat(attendanceId, pageNumber, pageSize) {
        let res = await http.get(`v1/attendant/${attendanceId}?PageNumber=${pageNumber}&PageSize=${pageSize}`);
        return res.data;
    }

    async sendMessage(attendanceId, data, FunctionProgress) {
        let res = await http.post(`v1/attendant/${attendanceId}/send-message`, data,
            {
                onUploadProgress: (event) => {
                    let progress = Math.round((event.loaded * 100) / event.total);
                    FunctionProgress(progress);
                },
            });

        return res.data;
    }

    async sendMessageToAttendant(attendanceId, data, FunctionProgress) {
        let res = await http.post(`v1/attendant/${attendanceId}/send-message-attendant`, data,
            {
                onUploadProgress: (event) => {
                    let progress = Math.round((event.loaded * 100) / event.total);
                    FunctionProgress(progress);
                },
            });

        return res.data;
    }

    async finishAttendance(attendanceId, namePlataform) {
        let res = await http.post(`v1/attendant/${attendanceId}/end-attendance?platform=${namePlataform}`);
        return res.data;
    }

    async createAttendanceActive(data, namePlataform) {
        namePlataform = 'Whatsapp';
        let res = await http.post(`v1/attendant/create-attendance?platform=${namePlataform}`, data);
        return res.data;
    }

    async sendSms(data) {
        let res = await http.post(`v1/sms/send-sms`, data);
        return res.data;
    }

}