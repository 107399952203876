import { HttpResponse } from '@aspnet/signalr';
import http from '../http-common'

export default class UserServices {
    async getAll(company_id){
        let res = await http.get(`v1/user?companyId=${company_id}`);
        return res.data;
    }

    async getAllActive(company_id){
        let res = await http.get(`v1/user/active?companyId=${company_id}`);
        return res.data;
    }

    async getAllExternal(company_id){
        let res = await http.get(`v1/user/external?companyId=${company_id}`);
        return res.data;
    }

    async getPaper(company_id){
        let res = await http.get(`v1/user/${company_id}/paper`)
        return res.data;
    }

    async save(user){
        let res = await http.post(`v1/user`, user);
        return res.data;
    }
    
    async edit(user){
        let res = await http.put(`v1/user/${user.id_usuario}`, user);
        return res.data;
    }

    async search(id_user){
        let res = await http.get(`v1/user/${id_user}`);
        return res.data;
    }
    
    async disableAndEnableUser(id_user, id_status){
        let res = await http.delete(`v1/user/${id_user}/${id_status}`);
        return res.data;
    }
    
    async transferAttendanceToQueueWait(id_user){
        let res = await http.post(`v1/user/${id_user}/transfer-attendance-to-queue-wait`);
        return res.data;
    }

    async changeStatus(code_status, motivePause){
        let res = await http.post(`v1/user/${code_status}/change-status?motivePause=${motivePause}`);
        return res.data;
    }

    async getLogStatus(){
        let res = await http.post(`v1/user/get-log-status`);
        return res.data;
    }

    async Logout(){
        let res = await http.post(`v1/user/logout`);
        return res.data;
    }

    async AddUserBatch(user_batch){
        let res = await http.post(`v1/user/batch`,user_batch)
        return res.data
    }

    async getUserExcel(company_id){
        let res = await http.get(`v1/user/export?companyId=${company_id}`, {responseType: 'blob'})
        return res.data
    }

    async updatePassword(userId, passwords){
        let res = await http.put(`v1/user/update-password/${userId}`, passwords);
        return res.data
    }
}