import Axios from 'axios'
import { ENV } from '@/utils/env'
import ChatProvider from '@/provider/ChatProvider';

let _chatProvider = new ChatProvider();

const createAxios = Axios.create({
    baseURL: ENV.BASE_URL
})


createAxios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token_plataforma_digital')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    config.headers.tenant = ENV.TENANT;

    return config
}, (err) => {
    return Promise.reject(err)
})

createAxios.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error.response.status === 401) {
        localStorage.clear()
        _chatProvider.disconnect();
        window.location = '/'
    }
    return Promise.reject(error)
})

export default createAxios;