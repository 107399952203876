import http from '../http-common'

export default class ParamService{

    async getParams(){
        let res = await http.get(`v1/parameter/parameters`);
        return res;
    }

    async getParametersByTicketIdAndQueueId(ticketId, queueId){
        let res = await http.get(`v1/parameter/parameters-ticket?ticketId=${ticketId}&queueId=${queueId}`);
        return res;
    }

    async getParametersByTicketIdAndQueueIdChat(attendenceId, queueId){
        let res = await http.get(`v1/parameter/parameters-ticket-chat?attendenceId=${attendenceId}&queueId=${queueId}`);
        return res;
    }

    async updateForm(ticketId, parameters){
        let res = await http.post(`v1/parameter/${ticketId}/update-form`, parameters);
        return res;
    }

    async updateFormChat(attendenceId, parameters){
        let res = await http.post(`v1/parameter/${attendenceId}/update-form-chat`, parameters);
        return res;
    }

    async clearForm(ticketId){
        let res = await http.put(`v1/parameter/${ticketId}/clear-form`);
        return res;
    }

    async clearFormChat(attendenceId){
        let res = await http.put(`v1/parameter/${attendenceId}/clear-form-chat`);
        return res;
    }
}