<template>
  <div :class="aliansceSetup? 'container-widget-aliansce flex column no-wrap': formulaSetup?  'container-widget-formula flex column no-wrap' : 'container-widget flex column no-wrap'">
  <audio hidden ref="audio_new_message">
    <source src="../assets/audio/audio_message.mp3" type="audio/mpeg">
  </audio>
    <div v-if="!aliansceSetup" class="card-content-chat-header flex row" :style="`background-color: ${cor_primary} !important`">
      <div class="col-10 row justify-start items-center">
        <label class="q-ml-sm text-white text-bold">{{ name_channel }}</label>
      </div>
      <div class="col-2 row justify-center items-center">
        <q-btn class="" flat round color="white" icon="remove" @click="closeChat"/>
      </div>

    </div>
    
    <div class="card-content-chat-body q-pa-md" >
      <q-infinite-scroll @load="onLoadMessages" id="scrollArea" ref="scrollAreaRef" :offset="5"
                         reverse class="scroll">
        <template v-slot:loading>
          <div class="row justify-center q-my-md">
            <CosmosLoading class="loading-cosmos"/>
          </div>
        </template>
        <div v-for="message in messages" :key="message.id_mensagem" :class="aliansceSetup? 'aliansceSetup' : '' ">
          <q-chat-message :text="[message.mensagem]" size="10"
         
                          v-if="!message.cliente || message.cliente == 'false'" :bg-color= "aliansceSetup? 'positive shadow-2' :'container-answer-menssage-chat shadow-2'">
            <template v-slot:avatar v-if="avatar_bot != ''">
              <img class="q-message-avatar q-message-avatar--received" :src="avatar_bot">
            </template>

            <template v-slot:stamp v-if="name_bot != ''">
              <label v-if="!aliansceSetup">  <strong>{{name_bot}}</strong>  {{ formatDate(message.data_mensagem) }}</label>
              <label v-else>    {{ formatDateAliansce(message.data_mensagem) }}</label>
            </template>

            <div v-if="message.filepath == null" >
              <span v-html="message.mensagem.replace(/(?:\r\n|\r|\n)/g, '<br>')"/>
              
              <div :key="button" v-for="button in sortButtons(message.buttons)" >
                <q-btn color="secondary" class="btn-selected-option q-mt-sm" :style="`background-color: ${cor_button} !important`" :label="button"
                       @click="sendMessageForClick(button)"/>
              
              </div>
            </div>

            <div v-else>
              <a :href="urlAwsBucket + message.filepath"
                 download
                 target="_blank"
                 class="q-pr-md">
                {{ message.mensagem }}
                <q-icon name="download" color="blue" size="sm" alt="download"/>
              </a>
            </div>
          </q-chat-message>
         
          <q-chat-message size="10" v-else
                          :stamp="formatDate(message.data_mensagem)" sent :bg-color="'positive shadow-2'">
            <template v-slot:stamp>
              <label class="text-client-message" v-if="!aliansceSetup"> <strong>Cliente</strong> {{ formatDate(message.data_mensagem) }}</label>
              <label class="text-client-message" v-else> {{ formatDateAliansce(message.data_mensagem) }}</label>
            </template>
            <div v-if="message.filepath == null"  class="text-client-message">
              <span v-html="message.mensagem.replace(/(?:\r\n|\r|\n)/g)"/> 
              <div :key="button" v-for="button in message.buttons">
                <q-btn color="secondary" class="btn-selected-option q-mt-sm" :style="`background-color: ${cor_button} !important`" :label="button"
                       @click="sendMessageForClick(button)"/>
              </div>
            </div>

            <div v-else>
              <a :href="urlAwsBucket + message.filepath"
                 download
                 target="_blank"
                 class="q-pr-md">
                {{ message.mensagem }}
                <q-icon name="download" color="blue" size="sm" alt="download"/>
              </a>
            </div>

          </q-chat-message>
        </div>
        <q-chat-message bg-color="container-answer-menssage-chat shadow-2"  v-if="typingNotification">
            <div class="loader">
              <span  class="loader__dot">.</span><span  class="loader__dot">.</span><span  class="loader__dot">.</span>
            </div>
        </q-chat-message>
        <div class="flex row justify-center">
          <q-circular-progress
              v-if="showProgressCircularFile"
              show-value
              class="text-black q-ma-md flex justify-end align-end"
              :value="loadingFileValue"
              size="50px"
              color="black"
          />
        </div>
      </q-infinite-scroll>
    </div>
    <div class="flex justify-center items-center" v-if="newAttendance && newAttendanceButton">
      <q-btn unelevated rounded color="primary" :style="`background-color: ${cor_secondery} !important`"
             @click="startAttendance" label="INICIAR UM NOVO ATENDIMENTO"/>
    </div>
    <div class="content-input-message-send q-px-sm flex items-center" v-else>
      <q-input outlined bottom-slots style="width: 100%;" :readonly="messageWithBttons" @keydown="(e)=>SendingTypeEvent(e)" @keyup.enter="sendMessage" type="text" bg-color="white"
               v-model="text" dense>
        <template v-slot:prepend>
          <q-btn v-show="button_file" round dense flat @click="chooseFiles()" color="blue" icon="attach_file"/>
        </template>
        <template v-slot:after>
          <q-btn round dense @click="sendMessage" flat color="black" icon="send"/>
        </template>
      </q-input>
      <input
          ref="refInputMidia"
          type="file"
          id="file"
          @change="uploadFile($event)"
          style="display: none"
      />
    </div>
  </div>
</template>
<script setup>

import {ref, onMounted} from 'vue'
import ChatServices from '@/services/ChatServices';
import {useStore} from "vuex";
import {useQuasar, setCssVar, getCssVar  } from "quasar";
import CosmosLoading from "@/components/common/CosmosLoading.vue";
import WidgetProvider from "@/provider/WidgetProvider";
import {useRoute} from "vue-router";
import { ENV } from "@/utils/env";

const $q = useQuasar();
const store = useStore();
const refInputMidia = ref(null);
const _widgetProvider = new WidgetProvider();
const audio_new_message = ref();
let aliansceSetup = ref(false);
let formulaSetup = ref(false);
let typingNotification = ref(false);
let newAttendanceButton = ref(false);
let _typingTimeout = "";
let _operatorConnectionString = ref("");
let urlAwsBucket = ENV.URL_AWS_BUCKET;
let button_file = ref(false);
let cor_primary = ref("#00498f");
let cor_secondery = ref("");
let cor_button = ref("");
let text_color = ref("")
let avatar_bot = ref("");
let name_bot = ref("");
let name_channel = ref("Chat");
let showProgressCircularFile = ref(false);
let _route = new useRoute();
let _chatServices = new ChatServices();
let _quantityMessage = ref(10);
let _pageMessage = ref(1);
let _containsMessageInHistory = ref(true);
let _chatService = new ChatServices();
let loadingFileValue = ref(0);
let messageWithBttons = ref(false);

let attendance = ref(null);
let messages = ref([]);


let text = ref("");
let newAttendance = ref(true);

function chooseFiles() {
  refInputMidia.value.click();
}

function closeChat() {
  window.parent.postMessage({close: true}, '*');
}

function _setCookie(cname, cvalue, exdays) {
  let d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function _getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


function startAttendance() {
  _validate();
  button_file.value = false;
  let widget = {
    CompanyId: _route.query.company,
    ChannelId: _route.query.widget,
    Uuid: _route.query.uuid,
    Token: _getCookie("cosmos_widget_token")
  };
  store.state.socket_widget.invoke("HandleAttendance", widget);
}

function _autoStart(){
  if(_route.query.auto){
    document.getElementById('scrollArea').style.height = '83vh'
    startAttendance();
  }
}

function sendMessageForClick(value) {
  text.value = value;
  sendMessage();
  text.value = "";
}

function formatDate(date) {
  return new Date(date).toLocaleString() === 'Invalid Date' ? date : new Date(date).toLocaleString("pt-BR");
}

function formatDateAliansce(date){
  let dateTime = new Date(date).toLocaleString() === 'Invalid Date'? date : new Date(date);
  let time 
  if(dateTime instanceof Date){
    time = dateTime.getHours().toString().padStart(2,0) +':' + dateTime.getMinutes().toString().padStart(2,0);
  }else{ 
    let fragmentedDate = dateTime.split(' ');

    time = fragmentedDate[1].substring(0,fragmentedDate[1].lastIndexOf(':'));
  }
  return time;
}

function _audioNewMessage() {
  audio_new_message.value.play();
}

async function sendMessage() {
  const formData = _getFormDataMessage();
  _chatServices.sendMessageToAttendant(attendance.value.id_atendimento, formData, loadingFile)
      .catch((error) => {
        $q.notify({
          message: error.response.data.toString(),
          color: "red-8",
          position: "top",
        });
        showProgressCircularFile.value = false;
      });
  text.value = "";
}

function _getFormDataMessage() {
  const formData = new FormData();
  formData.append("CompanyId", _route.query.company);
  formData.append("ChannelId", _route.query.widget);
  formData.append("Body", text.value);
  formData.append("Token", _getCookie("cosmos_widget_token"));
  return formData;
}

async function onLoadMessages(index, done) {
  if (attendance.value != null) {
    const CONTAINS_MESSAGE = messages.value.length > 0;
    if (CONTAINS_MESSAGE) {
      if (_containsMessageInHistory.value) {
        _pageMessage.value++;
        let data = await _chatService.getMessageForChat(attendance.value.id_atendimento, _pageMessage.value, _quantityMessage.value);
        _containsMessageInHistory.value = data.length > 0;
        data.reverse().forEach(message => {
          messages.value.unshift(message);
        });
      } else {
        document.getElementById("scrollArea").scrollBy({
          top: 50,
          left: 0,
          behavior: "smooth"
        });
      }
    }
  }
  done();
}

function _validate() {
  let validate = {
    CompanyId: _route.query.company,
    ChannelId: _route.query.widget,
    Token: _route.query.id,
    Cookie: _getCookie("cosmos_widget_token")
  };

  store.state.socket_widget.invoke("Validate", validate);

}

function _validadeAliansceSetup(){
  if(_route.query.auto == 'true' && _route.query.company == '99'){
    aliansceSetup.value = true;
  }else{ 
    newAttendanceButton.value = true
  }
}

function _backgroundFormula(){
  if(_route.query.company == '102'){
    formulaSetup.value = true;
  }
}

function _listingsInAttendance(){
  store.state.socket_widget.on("InAttendance", () => {
    button_file.value = true;
  });
}

function _listeningToTypingEvent(){
  store.state.socket_widget.on("Typing", (pressingKey, connectionString)=>{
    _operatorConnectionString.value = connectionString;
    if(pressingKey){
      typingNotification.value = true;
    }else{
      typingNotification.value = false;
    }

  })
}

function _outOfBusinessHours(){
  store.state.socket_widget.on("NotWorkingChannelMessageCustomer", (conversationId, message) => {
    
    if(!message.cliente){
    _audioNewMessage();
    }

    messages.value.push(message);
    
  });
}

function _receivedMessage() {
  store.state.socket_widget.on("ReceiveMessageCustomer", (conversationId, message) => {
    if(message.buttons != null && message.buttons.length > 0){
      messageWithBttons.value = true;
    }else{
      messageWithBttons.value = false;
    }
    if(!message.cliente){
    _audioNewMessage();
    }
    newAttendance.value = true;
    messages.value.push(message);
    newAttendance.value = false;

    
    _scroll();
  });
}

function _scroll() {
  let sizeScreen = document.getElementById("scrollArea").offsetHeight;
  setTimeout(() => {
    document.getElementById("scrollArea").scrollBy({
      top: sizeScreen,
      left: 0,
      behavior: "smooth"
    });
  }, 100);
}

function _listingCookie() {
  store.state.socket_widget.on("SaveCookie", (token) => {

    _setCookie('cosmos_widget_token', token, 7);
  });
}

function _listingEndAttendance() {
  store.state.socket_widget.on("EndAttendance", (queue) => {
    newAttendance.value = true;
  });
}

function _listingReconect() {
  store.state.socket_widget.on("ReconnectServer", () => {
    if (attendance.value != null) {
      var attendanceId = attendance.value.id_atendimento;
      store.state.socket_widget.invoke("AddConnectionId", attendanceId.toString());
    }
  });
}

function _listingAttendance() {
  store.state.socket_widget.on("Attendance", (att) => {
    attendance.value = att;
  });
}

function _listingHistoryMessage() {
  store.state.socket_widget.on("HistoryMessage", (msgs) => {
    _scroll();
    messages.value = msgs;
    newAttendance.value = false;
  });
}

function _survey(){
  store.state.socket_widget.on("TransferToSurvey", ()=>{
   
    button_file.value = false
   
  })
}

async function uploadFile(event) {
  if (event != null) {
    showProgressCircularFile.value = true;
    _scroll();
    const formData = _getFormDataMessage();
    formData.append("File", event.target.files[0]);

    await _chatServices.sendMessageToAttendant(attendance.value.id_atendimento, formData, loadingFile)
        .then((e) => {
          showProgressCircularFile.value = false;
        })
        .catch((error) => {
          $q.notify({
            message: error.response.data.toString(),
            color: "red-8",
            position: "top",
          });
          showProgressCircularFile.value = false;
        });

  }
}

function SendingTypeEvent(event){
  if(_operatorConnectionString.value){
    if(_typingTimeout != ''){
      clearTimeout(_typingTimeout)
    }
    if(event.key !="Backspace" && event.key!="Enter" && event.key!="Alt" && event.key!="Tab"){
      store.state.socket_widget.invoke("Typing", true, _operatorConnectionString.value)
      _typingTimeout = setTimeout(()=>{
        store.state.socket_widget.invoke("Typing", false, _operatorConnectionString.value)
      },4000);
    }else{
      store.state.socket_widget.invoke("Typing", false, _operatorConnectionString.value)
    }
  }
}

function _notifyBackEndOfClosedWindow(){
  addEventListener('beforeunload', async (e)=>{
    
    var websockeConnectionId = store.state.socket_widget.connectionId;

    websockeConnectionId = websockeConnectionId.toString();

    await store.state.socket_widget.invoke("ClosedWindow",websockeConnectionId, _operatorConnectionString.value);
  });
}

function loadingFile(value) {
  loadingFileValue.value = value;

}

function sortButtons(buttons) {
   if (buttons) {
      return buttons.sort((a, b) => a - b);
      
    } else {
      return [];
    }
  }

onMounted(async () => {
  await _widgetProvider.start();

  _validadeAliansceSetup();
  _backgroundFormula();
  _receivedMessage();
  _outOfBusinessHours();
  _listingCookie();
  _listingAttendance();
  _listingHistoryMessage();
  _listingEndAttendance();
  _listingReconect();
  _listingsInAttendance();
  _listeningToTypingEvent();
  _survey();
  _autoStart();
  _notifyBackEndOfClosedWindow()


  store.state.socket_widget.on("ReceivedWidget", (chat) => {

    name_channel.value = chat.nameChannel;
    if (chat.parameters.length != 0) {

      let collor_primary = chat.parameters.filter(f => {
        return f.parametro == 'cor_primaria_chat';
      });

      cor_primary.value = collor_primary[0].valor;

      let collor_secundary = chat.parameters.filter(f => {
        return f.parametro == 'cor_secundaria_chat';
      });

      cor_secondery.value = collor_secundary[0].valor;

      let text = chat.parameters.filter(f => {
        return f.parametro == 'cor_texto';
      });
      
      text_color.value = text[0].valor;

      let collor_button = chat.parameters.filter(f => {
        return f.parametro == 'cor_botao_chat';
      });

      cor_button.value = collor_button[0].valor;

      let avatar_bott = chat.parameters.filter(f => {
        return f.parametro == 'avatar_bot_chat';
      });

      avatar_bott[0]?.valor? avatar_bot.value = avatar_bott[0].valor : avatar_bot.value ;

      let name_bott = chat.parameters.filter(f => {
        return f.parametro == 'nome_bot_chat';
      });

      name_bott[0]?.valor? name_bot.value = name_bott[0].valor : name_bot.value;

      setCssVar('positive', cor_primary.value);

    }
  });
})

</script>
<style lang="scss" scoped>

.container-widget {
  height: 100vh;
  background: #eee !important;
}

.container-widget-aliansce {
  height: 100vh;
  background: white !important;
}

.container-widget-formula {
  height: 100vh;
  background-image: url('@/assets/images/horizontal_aformula_background.svg') !important;
  background-color: rgb(0, 151, 178);
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: $breakpoint-sm-max){
    background-image: url('@/assets/images/vertical_aformula_background.svg') !important;
  }
}


@keyframes blink {50% { color: transparent }}
.loader__dot { animation: 1s blink infinite }
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }

.card-content-chat-header {
  height: 60px;
  overflow: hidden;
  background-color: #f06544;
  overflow-x: hidden;
}

.text-client-message{ 
  color: v-bind(text_color) !important;
}

.scroll {
  height: 73vh;
  max-height: 95vh;
  overflow: auto;
  overflow-x: hidden;
}


.content-input-message-send {
  height: 1%;
  padding-top: 4px;
}

.aliansceSetup{
  font-family: 'Mark Pro Extra Light', sans-serif;
  font-size: 14px;
  font-weight: 300;
}

.btn-selected-option {
  background: var(--bg-color);
  color: white;
  border-radius: 11px;
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  outline: none;
  border-color: transparent;
  margin-bottom: 5px;
  padding: 3px 10px;
  display: block;
  cursor: pointer;
  border-width: thin;

  &:hover {
    color: var(--bg-color);
    background-color: white;
    border-color: var(--bg-color);
  }
}
</style>