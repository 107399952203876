import http from '../http-common'

export default class AuthServices {
    async auth(data){
        let res = await http.post("v1/user/authenticate", data);
        return res;
    }

    async logoutAnotherSession(userId){
        let res = await http.post(`v1/user/${userId}/logout-session`);
        return res;
    }
}