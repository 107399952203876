import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { ENV } from "@/utils/env";
import { useStore } from "vuex";
import { provide, inject } from 'vue'


export default class WidgetProvider {

    constructor() {
        this.store = useStore();
        this.connection = new HubConnectionBuilder()
            .withUrl(ENV.BASE_URL_SOCKET_WIDGET)
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();
    }

    async start() {
        this.store.commit('setSocketWidget', this.connection);
        await this.store.state.socket_widget.start().then(() => {
            console.log("Connected");
        })
            .catch(e => console.log("Connection failed", e));
    }
}