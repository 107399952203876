import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import money from 'v-money3'
import VueTheMask from 'vue-the-mask'
// import './registerServiceWorker'
import { router } from './router'
import store from './store'
import { QuillEditor } from '@vueup/vue-quill'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

import '@/styles/_global.scss'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'drawflow/dist/drawflow.min.css'

import excel from 'vue-excel-export'

document.title = "Cosmos " + document.location.hostname.split('.')[0];

var app = createApp(App)
var pinia = createPinia()
app.use(Quasar, quasarUserOptions)
app.component('QuillEditor', QuillEditor)
app.use(store)
app.use(router)
app.use(pinia)
app.use(excel)
app.use(money)
app.use(VueTheMask)
app.mount('#app')
