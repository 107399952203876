<template>
    <q-dialog :model-value="showDialog" position="right" >
    <q-card style="width: 350px">
      <q-linear-progress :value="1" color="primary" />

      <q-card-section class="row items-center no-wrap">
        <div>
          <div class="text-weight-bold">+{{ number }}</div>
          <div class="text-grey q-mt-sm">Você recebeu uma nova chamada que será respondida automaticamente.</div>
        </div>

        <q-space />

        <q-btn @click="showCallInbound" rounded color="primary" class="q-ml-xs q-mt-md" size="md" label="Exibir"/>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { useDialogPluginComponent } from 'quasar'
  import { ref } from 'vue'
  const props = defineProps({
    showDialog:{
      type: Boolean,
      dafult: false
    },
    number: Number
  });

  const dialog = ref(false);

  const position = ref('right')
  const emit = defineEmits(['showCall'])


  function showCallInbound(){
    emit('showCall')
  }

</script>
<style scoped>
.fixed-right, .absolute-right {
    top: 0;
    right: 0;
    bottom: auto !important;
}
</style>
