<template>
    <div class="col-12 row flex" v-if="data.attendanceId">
        <div class="flex col-12 row justify-center items-center" v-if="loading">
            <q-spinner-hourglass color="primary" size="2rem" />
        </div>
        <div class="col-12 q-mb-sm flex justify-end" v-if="!loading">
            <q-btn class="btn-edit cursor-pointer" @click="editContact">
                Editar
            </q-btn>
        </div>

        <div class="contact-form-container" style="max-height: 75vh; overflow-y: auto;">
            <div class="row col-12 flex card-form-contact" v-if="!loading">
                <div class="col-12">
                    <div class="col-12">
                        <label class="label-title-input">Nome</label>
                        <q-input outlined v-model="contact.nome" bg-color="white" dense />
                    </div>
                </div>
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">CPF</label>
                        <q-input outlined v-model="contact.cpf" mask="###.###.###-##" unmasked-value bg-color="white"
                            dense />
                    </div>
                </div>

                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">CNPJ</label>
                        <q-input outlined v-model="contact.cnpj" mask="##.###.###/####-##" unmasked-value bg-color="white"
                            dense />
                    </div>
                </div>

                <!-- TELEFONE (Até 3 campos disponíveis) -->
               
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Telefone</label>
                        <q-input outlined v-model="contact.telefone"
                            v-mask="['(+##) ## ####-####', '(+##) ## #####-####']"
                            bg-color="white" dense />
                    </div>
                </div>

                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Telefone</label>
                        <q-input outlined v-model="contact.telefone1"
                            v-mask="['(+##) ## ####-####', '(+##) ## #####-####']"
                            bg-color="white" dense />
                    </div>
                </div>
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Telefone</label>
                        <q-input outlined v-model="contact.telefone2"
                            v-mask="['(+##) ## ####-####', '(+##) ## #####-####']"
                            bg-color="white" dense />
                    </div>
                </div>
                
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">E-mail</label>
                        <q-input outlined :class="[contact.hasOwnProperty('email') ? 'readonly' : '']"
                            v-model="contact.email" bg-color="white" dense 
                            :readonly="!editavel" />
                    </div>
                </div>
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Gênero</label>
                        <q-select class="input-form" outlined v-model="contact.genero" dense map-options emit-value
                            :options="genre_option" bg-color="white">
                            
                        </q-select>
                    </div>
                </div>
              <!--   <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Cep</label>
                        <q-input outlined v-model="schema_address.cep" mask="#####-###" unmasked-value @change="getAddress"
                            bg-color="white" dense />
                    </div>
                </div> -->
                <div class="col-12" v-if="schema_address.cep != ''">
                    <div class="col-12 q-mt-sm">
                        <div class="col-12">
                            <label class="label-title-input">Endereço</label>
                            <q-input outlined v-model="schema_address.endereco" bg-color="white" dense />
                        </div>
                    </div>
                    <div class="col-12 q-mt-sm">
                        <div class="col-12">
                            <label class="label-title-input">Bairro</label>
                            <q-input outlined v-model="schema_address.bairro" bg-color="white" dense />
                        </div>
                    </div>
                    <div class="col-12 q-mt-sm">
                        <div class="col-12">
                            <label class="label-title-input">Cidade</label>
                            <q-input outlined v-model="schema_address.cidade" bg-color="white" dense />
                        </div>
                    </div>
                    <div class="col-12 q-mt-sm">
                        <div class="col-12">
                            <label class="label-title-input">UF</label>
                            <q-input outlined v-model="schema_address.uf" maxlength="2" bg-color="white" dense />
                        </div>
                    </div>
                    <div class="col-12 q-mt-sm">
                        <div class="col-12">
                            <label class="label-title-input">Nº</label>
                            <q-input outlined v-model="schema_address.numero" bg-color="white" dense />
                        </div>
                    </div>
                    <div class="col-12 q-mt-sm">
                        <div class="col-12">
                            <label class="label-title-input">Complemento</label>
                            <q-input outlined v-model="schema_address.complemento" bg-color="white" dense />
                        </div>
                    </div>
                </div>

                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Data de Nascimento</label>
                        <q-input outlined v-model="contact.dataNascimento" bg-color="white" dense />
                    </div>
                </div>
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Idade</label>
                        <q-input outlined v-model="contact.idade" bg-color="white" dense />
                    </div>
                </div>
                
                <!-- <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Auxílio Saúde (SIM ou NÃO)</label>
                        <q-select class="input-form" outlined v-model="contact.auxilioSaude" dense map-options emit-value
                            :options="auxilioSaudeOptions" bg-color="white">
                            <template v-slot:no-option>
                                <q-item>
                                    <q-item-section class="text-italic text-grey">
                                        Sem opção cadastrada
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-select>
                    </div>
                </div>
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Operadora</label>
                        <q-input outlined v-model="contact.operadora" bg-color="white" dense />
                    </div>
                </div> -->
                <!-- <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class ="label-title-input">Sexo</label>
                        <q-select class="input-form" outlined v-model="contact.sexo" dense map-options emit-value
                            :options="genre_option" bg-color="white">
                            <template v-slot:no-option>
                                <q-item>
                                    <q-item-section class="text-italic text-grey">
                                        Sem opção cadastrada
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-select>
                    </div>
                </div> -->
                <!-- <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Matrícula Servidor</label>
                        <q-input outlined v-model="contact.matriculaServidor" bg-color="white" dense />
                    </div>
                </div> -->
                              
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Orgão</label>
                        <q-input outlined v-model="contact.orgao" bg-color="white" dense />
                    </div>
                </div>
                
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Cargo</label>
                        <q-input outlined v-model="contact.cargo" bg-color="white" dense />
                    </div>
                </div>
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Renda</label>
                        <q-input outlined v-model="contact.renda" bg-color="white" dense />
                    </div>
                </div>
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Classe</label>
                        <q-input outlined v-model="contact.classe" bg-color="white" dense />
                    </div>
                </div>
                <!-- Endereço Residencial -->
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">CEP</label>
                        <q-input outlined v-model="schema_address.cep" mask="#####-###" unmasked-value @change="getAddress"
                            bg-color="white" dense />
                    </div>
                </div>
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Endereço Residencial</label>
                        <q-input outlined v-model="schema_address.endereco" bg-color="white" dense />
                    </div>
                </div>
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Número</label>
                        <q-input outlined v-model="schema_address.numero" bg-color="white" dense />
                    </div>
                </div>
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Complemento</label>
                        <q-input outlined v-model="schema_address.complemento" bg-color="white" dense />
                    </div>
                </div>
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Bairro</label>
                        <q-input outlined v-model="schema_address.bairro" bg-color="white" dense />
                    </div>
                </div>
                
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">Cidade</label>
                        <q-input outlined v-model="schema_address.cidade" bg-color="white" dense />
                    </div>
                </div>
                <div class="col-12 q-mt-sm">
                    <div class="col-12">
                        <label class="label-title-input">UF</label>
                        <q-input outlined v-model="schema_address.uf" maxlength="2" bg-color="white" dense />
                    </div>
                </div>
                

            </div> <!-- Campos do formulario-->
        </div>

    </div>
</template>
<script setup>



import { ref, onMounted, watchEffect, computed } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex';
import { useQuasar } from 'quasar';
import ContactServices from '@/services/ContactServices.js';

const emit = defineEmits(['email_contact_exist', 'email_doesnt_exist', 'email_exist', 'able_create_ticket']);
const props = defineProps([ 'data', 'editavel']);
const store = useStore();
const $q = useQuasar();
const _contactServices = new ContactServices();
let contact = ref({})
let loading = ref(false);

const genre_option = [
    { label: "Masculino", value: "M" },
    { label: "Feminino", value: "F" },
    { label: "Outros", value: "O" },
]

const auxilioSaudeOptions = [
    {label: "Sim", value: "S"},
    {label: "Não", value: "N"},

]

let schema_address = ref({
    "id_endereco": null,
    "endereco": "",
    "bairro": "",
    "cidade": "",
    "uf": "",
    "cep": "",
    "numero": "",
    "complemento": ""
})

async function editContact() {
    contact.value.address = schema_address.value;
    contact.value.telefone = (contact.value.telefone != null) ? contact.value.telefone.replace(/[^\d]/g, "") : null;
    contact.value.telefone1 = (contact.value.telefone1 != null) ? contact.value.telefone1.replace(/[^\d]/g, "") : null;
    contact.value.telefone2 = (contact.value.telefone2 != null) ? contact.value.telefone2.replace(/[^\d]/g, ""): null;
    await _contactServices.edit(contact.value).then(response => {
        $q.notify({
            message: 'Contato atualizado.',
            position: 'top',
            color: "green-8"
        })
        if (contact.value.email != null && contact.value.email != "") {
            emit('email_contact_exist', {
                'email': contact.value.email,
                'id': contact.value.id
            });
            emit('able_create_ticket');
            emit('email_exist');
        }
    }).catch((error) => {
        $q.notify({
            message: error.response.data,
            position: 'top',
            color: 'red-8'
        });
    });
}

async function _getDataContact(id_contact) {
    if (!props.data.attendanceId) {
        return;
    }
    loading.value = true;
    await _contactServices.search(id_contact).then(response => {
        contact.value = response;
        contact.value.id_empresa = store.state.userData.id_empresa;
        if (contact.value.address != null) {
            schema_address.value.endereco = response.address.endereco
            schema_address.value.bairro = response.address.bairro
            schema_address.value.cidade = response.address.cidade
            schema_address.value.uf = response.address.uf
            schema_address.value.numero = response.address.numero
            schema_address.value.complemento = response.address.complemento
            schema_address.value.id_endereco = response.id_endereco
            schema_address.value.cep = response.address.cep
        }
        loading.value = false;


        if (response.email != null && response.email != "") {
            emit('email_contact_exist', {
                'email': contact.value.email,
                'id': contact.value.id
            });
            emit('able_create_ticket');
        } else {
            emit('email_doesnt_exist');
        }
    }).catch((error) => {
        $q.notify({
            message: error.response.data,
            position: 'top',
            color: 'red-8'
        });
    });
}

function getAddress() {
    if (schema_address.value.cep.length != 8) return;
    axios
        .get(`https://viacep.com.br/ws/${schema_address.value.cep}/json/`)
        .then((response) => {
            if (!response.data.cep) {
                $q.notify({
                    message: 'CEP não encontrado',
                    position: 'top',
                    color: 'red-8'
                })
            }

            if (contact.value.address !== null) {
                contact.value.address.endereco = response.data.logradouro
                contact.value.address.bairro = response.data.bairro
                contact.value.address.cidade = response.data.localidade
                contact.value.address.uf = response.data.uf
                contact.value.id_endereco = contact.value.id_endereco
            } else {
                schema_address.value.endereco = response.data.logradouro
                schema_address.value.bairro = response.data.bairro
                schema_address.value.cidade = response.data.localidade
                schema_address.value.uf = response.data.uf

            }
        })
}

watchEffect(() => {
    _getDataContact(props.data.contactId);
});


</script>
<style lang="scss" scoped>
.card-form-contact {
    max-height: 75vh;
    overflow-y: auto;
}

.label-title-input {
    color: #111930;
    font-weight: 400 !important;
    font-size: 14px;
}

.btn-edit {
    border-radius: 13px;
    background-color: #385299;
    color: #fff;
    font-size: 12px;
}</style>