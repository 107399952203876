<template>
    
    <TelephonyMain 
    :config="config" 
    @show_call="emit('show_call')"
    @init_call="(number) => getAttendance(number)"/>        

 
  
    <Transition v-show="!config.out_screen" name="bounce">
      <div class="card-content-information-chat position-right" :style="[style_card_information]">
        <div class="card-content-information-chat-header flex row col-12">
          <div class="q-pl-sm flex row col-12">
            <div class="row flex items-center justify-center cursor-pointer" @click="showInformation()"
                 :class="[close_information ? 'col-12' : 'col-2']">
              <q-icon name="keyboard_double_arrow_right" size="2rem" color="white"
                      v-if="!close_information"/>
              <q-icon name="keyboard_double_arrow_left" size="2rem" color="white" v-else/>
            </div>
            <q-separator vertical size="2px" color="blue-grey-8"/>
            <div class="flex row items-center justify-center col-9"
                 :class="[close_information ? '' : 'col-9']" v-if="!close_information">
              <div class="flex">
                <q-icon :name="data_tab.icon" size="1.5rem" color="white"/>
              </div>
              <div class="text-white q-pl-sm">
                <label>{{ data_tab.title }}</label>
              </div>
            </div>
          </div>
        </div>
        <q-separator size="2px"/>
        <div class="card-content-information-chat-body">
          <q-card>
            <q-tabs v-model="tab" dense class="text-grey" :vertical="close_information"
                    active-color="primary" indicator-color="primary" align="left">
              <q-tab name="info" icon="o_info" class="q-pa-xs"/>
              <q-separator vertical size="1px"/>
              <q-tab name="manage_acconts" icon="o_manage_accounts" class="q-pa-xs"/>
              <q-separator vertical size="1px"/>
              <q-tab name="history" icon="o_history" class="q-pa-xs"/>
              <q-separator vertical size="1px"/>
              <q-tab name="tabulation" icon="o_format_list_bulleted" class="q-pa-xs"/>
              <q-separator vertical size="1px"/>
            </q-tabs>

            <q-separator/>

            <q-tab-panels v-model="tab" v-if="!close_information">
              <q-tab-panel name="info">
                <!-- <SideInformationChatVue :ticket="false" :data="attendanceInProccess"></SideInformationChatVue> -->
              </q-tab-panel>
              <q-tab-panel name="manage_acconts">
                <!-- <SideContactChat @email_doesnt_exist="email_doesnt_exist = true"
                                 @able_create_ticket='able_create_ticket = true' @email_exist="create_ticket = true"
                                 @email_contact_exist="getContactEmail" :data="attendanceInProccess">
                </SideContactChat> -->
              </q-tab-panel>
              <q-tab-panel name="history">
                <!-- <SideHistoryAttendanceChat :data="attendanceInProccess"></SideHistoryAttendanceChat> -->
              </q-tab-panel>
              <q-tab-panel name="tabulation">
                <!-- <SideTabulationChat :data="attendanceInProccess" :tabulation_queue="tabulation_queue"
                                    @tabulation_saved="tabulationSaved = true" :loading="loadingTabulation">
                </SideTabulationChat> -->
              </q-tab-panel>
            </q-tab-panels>
          </q-card>
        </div>
      </div>
    </Transition>
            

</template>
<script setup>
import {ref, reactive, onMounted, computed} from 'vue';
import TelephonyMain from '@/components/web-rtc/TelephonyMain.vue';
import SideInformationChatVue from '@/components/chat/SideInformationChat.vue';
import SideTabulationChat from "@/components/chat/SideTabulationChat.vue";
import SideContactChat from '@/components/chat/SideContactChat.vue';
import SideHistoryAttendanceChat from '@/components/chat/SideHistoryAttendanceChat.vue';
import QueueServices from '@/services/QueueServices';


let style_card_information = ref({width: "5%"});
let close_information = ref(true);
let loadingTabulation = ref(false);
let _queueServices = new QueueServices();
let tabulationSaved = ref(false);
let tabulation_queue = ref({});
let attendanceInProccess = reactive({
  attendanceId: "",
  nameCustomer: "",
  platform: "",
  date: "",
  protocolo: "",
  nameChannel: "",
  contactId: null,
  idQueue: null
});

let tab = ref("info");

const props = defineProps({config:Object});
const emit = defineEmits(['init_call', 'terminated_call', 'received_call', 'show_call']);

let data_tab = computed(() => {
  switch (tab.value) {
    case "manage_acconts":
      return {title: "Dados do Cliente", icon: "o_manage_accounts"}
    case "history":
      return {title: "Histórico do Chat", icon: "o_history"}
    case "tabulation":
      return {title: "Tabulação", icon: "o_format_list_bulleted"}
    default:
      return {title: "Informações", icon: "o_info"}
  }
})

function showInformation() {
  close_information.value = !close_information.value;
  style_card_information.value.width = !close_information.value ? "25%" : "5%";
}

function _setAttendanceInProccess(attendance) {
  attendanceInProccess.attendanceId = attendance.id_atendimento;
  attendanceInProccess.nameCustomer = attendance.nome_cliente != null ? attendance.nome_cliente : attendance.protocolo;
  attendanceInProccess.platform = attendance.nome_canal;
  attendanceInProccess.date = attendance.data_entrada;
  attendanceInProccess.protocolo = attendance.protocolo;
  attendanceInProccess.nameChannel = attendance.nome_canal;
  attendanceInProccess.contactId = attendance.id_contato;
  attendanceInProccess.idQueue = attendance.id_fila;
  _containsMessageInHistory.value = true;
  _getTabulation();
}

async function _getTabulation() {
  if (!attendanceInProccess.idQueue) {
    return;
  }
  loadingTabulation.value = true
  _queueServices.search(attendanceInProccess.idQueue).then(async (response) => {

    if (response.parametro_tabulacao.length == 0) {
      tabulationSaved.value = true;
      return;
    }
    tabulation_queue.value = response;

    await _getExistingTabulationParameters();
  });
}

async function _getExistingTabulationParameters() {
  await _queueServices
      .getTabulationByAttendanceId(attendanceInProccess.idQueue, attendanceInProccess.attendanceId)
      .then((response) => {
        let finish_tabulation = true;
        if (response.length > 0) {
          tabulation_queue.value.parametro_tabulacao = response;

          tabulation_queue.value.parametro_tabulacao.forEach((item) => {
            if (
                item.obrigatorio_parametro == true &&
                item.valor_informacao == null
            ) {
              finish_tabulation = false;
              return;
            }
          });

          if (finish_tabulation == false) {
            tabulationSaved.value = false;
          } else {
            tabulationSaved.value = true;
          }
        }

        loadingTabulation.value = false
      });
}

function getAttendance(number){
    showInformation();
}


</script>
<style lang="scss" scoped>
.chat {
  width: 100%;
}

.position-right{
    position: absolute;
    right: 0;
}

.newMessageOrAttendance {
  -webkit-animation: bg-new-attendence .75s linear infinite;
  -moz-animation: bg-new-attendence .75s linear infinite;
  -ms-animation: bg-new-attendence .75s linear infinite;
  -o-animation: bg-new-attendence .75s linear infinite;
  animation: bg-new-attendence .75s linear infinite;
}

.label-input-form {
  color: #111930;
  font-weight: 400 !important;
  font-size: 14px;
}

.reponsive-image {
  width: calc(1rem + 2vw) !important;
  height: calc(1rem + 2vw) !important;
}


.label-name {
  label {
    font-weight: 500;
    font-size: calc(0.04rem + 1vw);
  }
}

.btn-send-active-active-chat {
  border-radius: 13px;
  background-color: #385299;
  color: #fff;
  font-size: 12px;
}

.secundary-label {
  width: 100%;

  label {
    font-size: calc(0.1rem + 0.7vw);
  }
}

.form-attendance-active {
  height: 53vh;
  overflow-y: auto;
}

.content-information-header {
  label {
    font-weight: 400;
    font-size: calc(0.2rem + 0.6vw);
    color: #000000;
  }
}

.time-card {
  label {
    font-weight: 400;
    font-size: calc(0.2rem + 0.6vw);
    color: #000000;
  }
}

.scroll {
  height: 73vh;
  max-height: 95vh;
  overflow: auto;
  overflow-x: hidden;
}

.card-list-chat-page {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(133.17% 32.16% at 59.58% 51.43%, rgba(237, 241, 250, 0.56) 0%, rgba(237, 241, 250, 0.35) 100%);
  height: 100vh;
  width: 20%;
  box-shadow: 4px 0 4px rgba(0, 0, 0, 0.116);

  .card-list-chat-page-header {
    background: linear-gradient(90deg, #1d2c5a 3.83%, #002a6a);

    .input-search {

      input {
        width: 100%;
        background-color: #fff;
        border-radius: 23px;
        border: none;
      }

      :focus {
        outline: none !important;
      }
    }
  }

  .card-list-chat-page-body {
    max-height: 95%;
    overflow-y: auto;
    overflow-x: hidden;

    .cards-list-chat {
      height: 10vh;
      background: rgba(255, 255, 255, 0.4);

      :hover {
        background: rgba(255, 255, 255, 1);
      }

    }
  }
}

.loading-cosmos {
  height: 3rem;
  width: 3rem;
}

.card-content-chat {
  height: 100vh;
  box-shadow: 4px rgba(0, 0, 0, 0.418) 4px 0px;

  .card-content-chat-header {
    height: 15vh;

    .header-information {
      background-color: #fafafa;
      height: 60%;
      width: 98%;
      border-radius: 5px
    }

  }

  .card-content-chat-body {
    height: 100%;

    overflow-x: hidden;

  }

}

.card-content-information-chat {
  background: white;
  height: 100vh;

  .card-content-information-chat-header {
    background: linear-gradient(90deg, #1d2c5a 3.83%, #002a6a);
    height: 8vh;
  }

  .card-content-information-chat-body {
    max-height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }
}

@keyframes bg-new-attendence {
  0% {
    background: green;
  }
  50% {
    background: white;
  }
  100% {
    background: green;
  }
}
</style>