<template>
    <div class="card-form-information q-pb-md" v-if="data.attendanceId != ''">
        <div class="row">
            <div class="col-12">
                <div class="col-12"><label class="text-bold">Protocolo de Atendimento</label></div>
                <div class="col-12">
                    {{ data.protocolo }}
                    <q-btn flat round icon="o_content_copy" @click="doCopy" />
                </div>
            </div>
            <div class="col-12 q-mt-sm">
                <div class="col-12"><label class="text-bold">Início</label></div>
                <div class="col-12">{{ data.date }}</div>
            </div>
            <div class="col-12 q-mt-lg">
                <div class="col-12"><label class="text-bold">Canal</label></div>
                <div class="row q-col-gutter-xs q-mt-sm">
                    <div class="col-2">
                      <q-item-section avatar>
                        <q-avatar class="icon-received" icon="o_forward_to_inbox" color="primary" text-color="white" />
                      </q-item-section>
                    </div>
                    <div class="col-8">
                        <label class="text-bold">{{ data.nameCustomer }}</label>
                        <div class="col-12">{{ data.nameChannel }}</div>
                    </div>
                </div>
            </div>
            <div class="col-12 row q-mt-lg" v-if="ticket">
                <div class="col-12">
                    <label class="text-bold">
                        Mudar Status
                    </label>
                </div>
                <div class="col-12 q-mt-sm">
                    <q-select 
                        class="input-form"
                        bg-color="white"
                        outlined 
                        dense
                        emit-value
                        map-options  
                        v-model="props.data.id_status_ticket" 
                        @update:model-value="changeStateTicket(props.data.id_status_ticket)"     
                        :options="state_option">
                        <template v-slot:no-option>
                            <q-item>
                                <q-item-section class="text-italic text-grey">
                                    Sem opção cadastrada
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-select>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useQuasar } from 'quasar';
import { copyText } from 'vue3-clipboard'
import TicketServices from "@/services/TicketServices";

let _ticketServices = new TicketServices();
const props = defineProps({ data: Object, ticket: Boolean })
const $q = useQuasar();
const emit = defineEmits(['change_state']);
const state_option = [
    { label: "Novo", value: 1},
    { label: "Pendente", value: 2 },
    { label: "Em andamento", value: 3 }
]
function doCopy() {
    copyText(props.data.protocolo, undefined, (error, event) => {
        if (!error) {
            $q.notify({
                message: `Protocolo ${props.data.protocolo} copiado!`,
                color: "green-8",
                position: "top"
            })
        }
    })
}
async function changeStateTicket(state){
    $q.loading.show();
    await _ticketServices.changeStateTicket(props.data.attendanceId, state).then(async (response) => {
        if(response){
            $q.notify({
                message: `Status alterado com sucesso!`,
                color: "green-8",
                position: "top"
            })
            if(state == 2)
                emit('change_state', state, 'Pendente');
            else
                emit('change_state', state, 'Em andamento');
        }
        else
            $q.notify({
                message: `Não foi possível alterar o status!`,
                color: "red-8",
                position: "top"
            })
        $q.loading.hide();
    }).catch((error) => {
        $q.notify({
            message: `Não foi possível alterar o status!`,
            color: "red-8",
            position: "top"
        })
        $q.loading.hide();
    });
}


</script>
<style lang="scss" scoped>
.card-form-information {
    height: 100%;
    overflow-y: auto;
}

.reponsive-image {
    width: calc(1rem + 2vw) !important;
    height: calc(1rem + 2vw) !important;
}
</style>