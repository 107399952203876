<template>
  <VueDragResize 
  v-show="calls.length > 0 && props.out_screen"
  style="z-index: 2 !important;"
    :isActive="true"
    :w="250"
    :h="90"
    :x="1080"
    :y="15"
    :parentLimitation="true"
    :isResizable="false">
    <div id="box" class="my-card box" >
    <div v-for="(selectedCall, index) in calls" :key="index" class="container-fluid q-pt-md">
      <div class="flex justify-between items-center q-pb-sm q-px-md">
        <div class="flex items-center">
          <div v-if="selectedCall.establishing || selectedCall.pos_attendance">
            <q-avatar  v-if="selectedCall.pos_attendance"
              size="20px" font-size="20px" color="grey-7"
              text-color="grey" icon="person" >
              <q-icon  style="position: absolute;"
              color="red" size="20px" name="close"/>
            </q-avatar>
            <q-avatar v-else size="20px" color="teal" text-color="white" icon="person" />
         </div>
          
          <div v-else>
            <q-spinner
                color="teal"
                size="2em"
                :thickness="4"
              />
          </div>
          <span class="text-white q-px-sm">
            {{ selectedCall.number }}
          </span>
        </div>
        <div class="text-white">
          <stopwatch :pause="selectedCall.pos_attendance"
           :number="selectedCall.number"
           :time="selectedCall.time" />
        </div>
      </div>
      <div v-if="selectedCall.pos_attendance">
        <div class="flex justify-center align-center color-black" style="margin-top: -15px;">
          <q-btn size="sm" @click="emit('endattendance', selectedCall.number)" round color="red" icon="call_end" >
                <q-tooltip>
                  Finalizar Atendimento
                </q-tooltip>
              </q-btn>
        </div>
    </div>
      <div v-else class="flex justify-center items-center" style="gap: 4%;">
        <q-btn @click="emit('dialpad')" size="sm" flat round color="white" icon="dialpad" >
              <q-tooltip>
                Teclado
              </q-tooltip>
            </q-btn>
            <q-btn flat size="sm" round color="white" icon="phone_forwarded" >  
              <q-tooltip>
                Transferir Chamada
              </q-tooltip>
              <q-popup-proxy :offset="[10, 10]">
                <q-card flat bordered>
                  <q-card-section class="q-pt-xs">
                    <div class="text-h6">Informe um Ramal para Transferir</div>
                  </q-card-section>
                  <q-card-section class="q-pt-xs">
                      <q-input outlined v-model="number_transfer"  />
                  </q-card-section>
                  <q-card-section class="q-pt-xs flex justify-center items-center">
                      <q-btn @click="emit('transfer', selectedCall.number, number_transfer)" color="secondary" icon-right="phone_forwarded" label="Transferir" />
                  </q-card-section>
                </q-card>
              </q-popup-proxy>
            </q-btn>
            <q-btn flat size="sm" round color="red" icon="call" @click="emit('hangup', selectedCall.number)" >
              <q-tooltip>
                Desligar Chamada
              </q-tooltip>
            </q-btn>

            <q-btn flat size="sm" round  @click="mute(index)" v-if="selectedCall.mute" color="red" icon="mic_off" >
              <q-tooltip>
                Remover Mute
              </q-tooltip>
            </q-btn>
            <q-btn flat size="sm" round @click="mute(index)" v-else color="white" icon="mic" >
              <q-tooltip>
                Colocar em Mute
              </q-tooltip>
            </q-btn>
            
            <q-btn flat size="sm" round @click="hold" v-if="callPause" color="red" icon="phone_paused" >
              <q-tooltip>
                Remove de Espera
              </q-tooltip>
            </q-btn>
            <q-btn flat size="sm" round @click="hold" v-else color="white" icon="phone_paused" > 
              <q-tooltip>
                Adicionar Em Espera
              </q-tooltip>
            </q-btn>
      </div>

      <q-separator v-if="index < (calls.length - 1)" class="q-mt-md" color="grey-8"/>
    </div>
  </div>
  </VueDragResize>
</template>
<script setup>
import { ref }from 'vue';
import VueDragResize from 'vue-drag-resize';
import Stopwatch from "./StopWatch.vue";

const props = defineProps({
  calls: Array,
  out_screen:{
    type: Boolean,
    default: false
  },
  order:{
    type:Number,
    default:1
  }
});


let name = 'Rodrigo'
let time = '3.04m'

let width = 0
let height = 0
let top = 0
let left = 750

function resize(newRect) {
  width = newRect.width;
  height = newRect.height;
  top = newRect.top;
  left = newRect.right;
}

const emit = defineEmits(['hangup', 'endattendance', 'dialpad', 'mute', 'hold']);

let callPause = ref(false)

let number_transfer = ref("");

async function mute(index){
  props.calls[index].mute = !props.calls[index].mute;
  emit('mute', props.calls[index].number, !props.calls[index].mute);
}

function hold(index){
  callPause.value = !callPause.value
 // emit('hold', props.calls[index].number);
}
</script>
<style scoped>

.my-card {
  height: auto;
  padding-bottom: 10px;
  width: 250px;
  left: 15px;
  top: 0px;
  border-radius: 16px;
  background-color: #2A375C;
}
.vdr.active:before{
  outline: none !important
}
</style>