<template>
  <div v-if="data.attendanceId">

    <div class="col-12 flex row justify-center items-center" v-if="loadingTabulation">
      <q-spinner-hourglass color="primary" size="2rem"/>
    </div>

    <div class="row form-tabulation q-pr-xs" v-if="!loadingTabulation">

      <div class="col-12" v-if="store.state.userData.id_empresa == 88">

        <div v-show="!isForm" class="row flex justify-center q-mt-md">
          <div class="col-12 flex justify-center">
            <q-icon name="search_off" size="60px" style="color: #0081dd;"/>
          </div>
          <div class="col-12 flex justify-center q-mt-md q-mb-lg">
            <p style="font-size: 15px;"><b>Não existe formulário cadastrado ou habilidato para a fila.</b></p>
          </div>
        </div>
       
        <div class="q-py-md" v-show="isForm">
          <label style="font-size: 25px;">Formulário</label>
        </div>

        <q-form ref="formTabulation" class="q-gutter-md">

          <div v-for="param in params_attendance" :key="param.parameterId">
            <div class="row" v-if="param.parameterType == 'input'">
              <div class="col-12 q-mb-sm">
                <label>{{ param.parameterName }}</label>
              </div>
              <div class="col-12">
                <q-input 
                    square outlined
                    dense 
                    bottom-slots 
                    type="text" 
                    bg-color="white" 
                    v-model="param.parameterValue">
                </q-input>
              </div>
            </div>

            <div class="row" v-if="param.parameterType == 'select'">
              <div class="col-12 q-mb-sm">
                <label>{{ param.parameterName }}</label>
              </div>
              <div class="col-12">
                <q-select 
                    square outlined 
                    dense
                    emit-value 
                    map-options
                    v-model="param.parameterValue"
                    :options="filterOptionsParam(param.parameterOptions)">
                </q-select>
              </div>
            </div>

            <div class="row q-mt-lg" v-if="param.parameterType == 'radio'">
              <div class="col-12 q-mb-sm">
                <label>{{ param.parameterName }}</label>
              </div>
              <div class="col-12">
                <q-option-group
                    square outlined 
                    v-model="param.parameterValue" 
                    inline
                    color="primary"
                    :options="filterOptionsParam(param.parameterOptions)">
                </q-option-group>
              </div>
            </div>

            <div class="row" v-if="param.parameterType == 'date'">
              <div class="col-12 q-mb-sm">
                <label>{{ param.parameterName }}</label>
              </div>
              <div class="col-12">
                <q-input 
                    square outlined 
                    dense
                    v-model="param.parameterValue">
                  <template v-slot:prepend>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                        <q-date v-model="param.parameterValue" mask="YYYY-MM-DD HH:mm">
                          <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Close" color="primary" flat />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                
                  <template v-slot:append>
                    <q-icon name="access_time" class="cursor-pointer">
                      <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                        <q-time v-model="param.parameterValue" mask="YYYY-MM-DD HH:mm" format24h>
                          <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Close" color="primary" flat />
                          </div>
                        </q-time>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </div>

            <div class="row" v-if="param.parameterType == 'text-area'">
              <div class="col-12 q-mb-sm">
                <label>{{ param.parameterName }}</label>
              </div>
              <div class="col-12">
                <q-input 
                    square outlined
                    dense 
                    type="textarea" 
                    bg-color="white" 
                    v-model="param.parameterValue">
                </q-input>
              </div>
            </div>

            <div class="row q-mt-lg" v-if="param.parameterType == 'money'">
              <div class="col-12 q-mb-sm">
                <label>{{ param.parameterName }}</label>
              </div>
              <div class="col-12">
                <money3 class="full-width q-pa-sm" v-model="param.parameterValue" v-bind="config"></money3>
              </div>
            </div>

          </div>
        </q-form>

        <div v-show="isForm" class="buttons-form q-mt-xs q-mb-lg">
          <div>
            <q-btn 
              unelevated rounded 
              color="red" 
              size="0.72rem" 
              label="Resetar formulário" 
              @click="clearForm()"/>
          </div>
          <div>
            <q-btn 
              unelevated rounded 
              color="primary" 
              size="0.72rem" 
              label="Salvar formulário" 
              @click="saveForm()"/>
          </div>
        </div>

        <q-separator class="q-mt-sm"></q-separator>

        <div v-show="!isTabulation" class="row flex justify-center q-mt-xl">
          <div class="col-12 flex justify-center">
            <q-icon name="search_off" size="60px" style="color: #0081dd;"/>
          </div>
          <div class="col-12 flex justify-center q-mt-md q-mb-lg">
            <p style="font-size: 15px;"><b>Não existe tabulação cadastrada ou habilitada para a fila.</b></p>
          </div>
        </div>
        
        <div class="col-12 q-mt-lg q-mb-sm" v-show="isTabulation">
          <label style="font-size: 25px;">Tabulação</label>
        </div>

        <div v-for="option in optionsArrayTabulation">
          <div class="col-12 q-mt-md">
            <label>Descrição {{option.layer}}</label>
          </div>
          <div class="col-12 q-mt-sm">
            <q-select square outlined 
                      dense
                      emit-value 
                      map-options
                      v-model="option.model" 
                      :options="option.options"
                      @update:model-value="updateSelect(option)">
            </q-select>
          </div>
        </div>
        
        <div v-show="isTabulation" class="buttons-tabulation q-mt-xs q-mb-lg">
          <div>
            <q-btn
              unelevated rounded 
              color="red" 
              size="0.72rem" 
              label="Resetar tabulação" 
              @click="clearTabulation()"/>
          </div>
          <div>
            <q-btn 
              unelevated rounded 
              color="primary" 
              size="0.72rem" 
              label="Salvar tabulação" 
              @click="saveTabulation()"/>
          </div>
        </div>

      </div>

      <div class="col-12" v-if="store.state.userData.id_empresa != 88">

        <q-form ref="formTabulation" class="q-gutter-md q-mt-xs">
          <div v-for="item in tabulation_queue_mock.parametro_tabulacao":key="item.id_tabulador_parametro">
            <div class="row q-mt-sm" v-if="item.tipo_parametro == 'shortText'">
              <div class="col-12">
                <label>{{ item.nome_parametro }}</label>
              </div>
              <div class="col-12">
                <q-input class="col-md-10" :rules="item.obrigatorio_parametro ? rules_empty : ''" lazy-rules
                         outlined bottom-slots type="text" bg-color="white" dense
                         v-model="item.valor_informacao"></q-input>
              </div>
            </div>
            <div class="row" v-if="item.tipo_parametro == 'logic'">
              <div class="col-12">
                <label>{{ item.nome_parametro }}</label>
              </div>
              <div class="col-12">
                <q-field v-model="item.valor_informacao" hide-bottom-space borderless lazy-rules
                         :rules="item.obrigatorio_parametro ? rules_empty : ''">
                  <q-radio v-model="item.valor_informacao" :val="radioOption.value"
                           :label="radioOption.label"
                           v-for="radioOption in [{ label: 'Sim', value: 'sim' }, { label: 'Não', value: 'nao' }]"/>

                </q-field>
              </div>
            </div>
            <div class="row q-mt-sm" v-if="item.tipo_parametro == 'longText'">
              <div class="col-12">
                <label>{{ item.nome_parametro }}</label>
              </div>
              <div class="col-12">
                <q-input type="textarea" cols="1" rows="2" class="col-md-10" outlined bottom-slots
                         bg-color="white" dense v-model="item.valor_informacao" lazy-rules
                         :rules="item.obrigatorio_parametro ? rules_empty : ''"></q-input>
              </div>
            </div>
            <div class="row q-mb-sm" v-if="item.tipo_parametro === 'list' && showList(item)">
              <div class="col-12">
                <label>{{ item.nome_parametro }}</label>
              </div>
              <div class="col-12">
                <q-select v-model="item.valor_informacao"
                          :options="filterOptions(item)"
                          outlined dense
                          :rules="item.obrigatorio_parametro ? rules_empty : ''" lazy-rules
                          option-value="descricao_tpl_opcoes" option-label="descricao_tpl_opcoes" emit-value map-options
                          @update:model-value="resetNextSteps(item)">
                </q-select>
              </div>
            </div>
          </div>
        </q-form>

        <div class="col-12 flex justify-end q-pt-sm">
          <q-btn unelevated rounded color="primary" size="0.8rem" label="Tabular" @click="onSubmit()"/>
        </div>

      </div>

    </div>
  </div>

</template>
<script setup>
import {ref, watchEffect, computed, onMounted, nextTick} from 'vue';
import {useStore} from 'vuex';
import {useQuasar} from 'quasar';
import TabulationServices from '@/services/TabulationServices';
import QueueServices from '@/services/QueueServices';
import TreeServices from '@/services/TreeServices';
import ParamService from '@/services/ParamService';

const props = defineProps({
  data: Object,
  tabulation_queue: Object,
  tabulation_attendance: Object,
  params_attendance: Object,
  isTicket: {
    default: false,
    type: Boolean
  },
  loading: {
    type: Boolean
  },
  isOpen: {
    type: Boolean,
    required: false
  }
})
let loadingTabulation = ref(props.loading);
const emit = defineEmits(['tabulation_saved','emitTabulation','emitForm']);
const store = useStore();
const $q = useQuasar();
const _tabulationServices = new TabulationServices();
const _queueServices = new QueueServices();
const _treeServices = new TreeServices();
const _paramsServices = new ParamService();
const config = {
    min: 0,
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    precision: 2,
    masked: false
}

let optionsArrayTabulation= ref([]);
let tabulation_queue_mock = ref({});
let tabulation = ref({
  id_tabulacao: null,
  id_ticket: null,
  id_atendimento: null,
  tabulacao_informacao: []
})
props.isTicket? tabulation.value.id_ticket = props.data.attendanceId : tabulation.value.id_atendimento = props.data.attendanceId; 

let showTabulation = ref(false);
let formTabulation = ref(null);
let rules_empty = [val => val != null && val.length > 1];
let isTabulation = ref(props.tabulation_queue.length > 0 ? true : false);
let isForm = ref(props.params_attendance.length > 0 ? true : false);


async function onSubmit() {
  formTabulation.value.validate().then(success => {
    if (success) {
      tabulation.value.tabulacao_informacao = [];
    
      tabulation_queue_mock.value.parametro_tabulacao.forEach(item => {
        tabulation.value.tabulacao_informacao.push({
          "id_tabulador_informacao": null,
          "id_tabulador_parametro": item.id_tabulador_parametro,
          "valor_informacao": item.valor_informacao,
          "id_atendimento": props.isTicket ? null : props.data.attendanceId,
          "id_ticket": props.isTicket ? props.data.attendanceId : null
        })
      })
      _tabulationServices.saveTabulationInformations(tabulation.value).then(response => {
        $q.notify({
          message: "Tabulação salva.",
          color: "green-8",
          position: "top"
        })

        _savedTabulation();

      })
    } else {
      $q.notify({
        message: "Preencha todos os campos obrigatórios",
        color: "red-8",
        position: "top"
      })
    }
  })
}

//SALVAR OU ATUALIZAR O ID DA TABULAÇÃO NO TICKET
async function saveTabulation(){
  $q.loading.show();
  loadingTabulation.value = true;
  let idTabulation = null;
  if(optionsArrayTabulation.value.length > 1){
    let lastTabulation = optionsArrayTabulation.value.slice(-1);
    let listDescription = verifyTabulation(optionsArrayTabulation, lastTabulation);
    if(listDescription.length == 0){
      loadingTabulation.value = false;
      $q.notify({
          message: `Preenchar o campo (Descrição ${lastTabulation[0].layer})`,
          color: "red-8",
          position: "top"
      });
      return;
    }
    idTabulation = await getIdTabulation(props.data.idQueue, listDescription);
    if(idTabulation == 0){
      loadingTabulation.value = false;
      $q.loading.hide();
      $q.notify({
          message: `Tabulação não encontrada, tente novamente.`,
          color: "red-8",
          position: "top"
      });
      return;
    }

    await updateTabulation(props.data.attendanceId, idTabulation);

    emit('emitTabulation', {
      "idAttendance": props.data.attendanceId,
      "idTabulation": idTabulation
    });

    _savedTabulation();
  }
}

//VERIFICAR SE TABULOU TODOS OS CAMPOS
function verifyTabulation(optionsArrayTabulation, lastTabulation){
  let listDescription = [];
  if(lastTabulation[0].model == ""){
    return listDescription;
  }else{
    optionsArrayTabulation.value.forEach((tabulation)=>{
        listDescription.push(tabulation.model);
    })
    return listDescription;
  }
}

//BUSCAR O ID DE TABULAÇÃO DE ACORDO COM OS MOTIVOS
async function getIdTabulation(idQueue, listDescription){
  let idTabulation;
  await _treeServices.getIdTabulation(idQueue, listDescription).then((response)=>{
    idTabulation = response.data;
  }).catch(err => {
      return 0;
  });
  return idTabulation;
}

//SALVAR O ID DE TABULAÇÃO NO BANCO NA TABELA DE TICKET
async function updateTabulation(attendanceId, idTabulation){
  
  if(props.isTicket){
    await _treeServices.updateTabulation(attendanceId, idTabulation).then((response)=>{
      if(response){
        $q.notify({
          message: "Tabulação salva com sucesso.",
          color: "green-8",
          position: "top",
        });
      }else{
        $q.notify({
          message: "Ocorreu um erro, tente novamente.",
          color: "red-8",
          position: "top",
        });
      }
    }).catch(err => {
      $q.notify({
          message: `${err.response.data ? err.response.data : err.message}`,
          color: "red-8",
          position: "top"
      })
    });
  }else{
    await _treeServices.updateTabulationChat(attendanceId, idTabulation).then((response)=>{
      if(response){
        $q.notify({
          message: "Tabulação salva com sucesso.",
          color: "green-8",
          position: "top",
        });
      }else{
        $q.notify({
          message: "Ocorreu um erro, tente novamente.",
          color: "red-8",
          position: "top",
        });
      }
    }).catch(err => {
      $q.notify({
          message: `${err.response.data ? err.response.data : err.message}`,
          color: "red-8",
          position: "top"
      })
    });
  }

  
}

//SALVAR OU FAZER UPDATE DO FORMULÁRIO NO BANCO
async function saveForm(){
  $q.loading.show();
  loadingTabulation.value = true;

  let paramsForm = props.params_attendance.filter((param)=>{
      if(param.parameterValue != null){
        return param;
      }
  });

  if(props.isTicket){
    await _paramsServices.updateForm(props.data.attendanceId, paramsForm).then((response)=>{
      if(response){
        $q.notify({
          message: "Formulário salvo com sucesso.",
          color: "green-8",
          position: "top",
        });
        $q.loading.hide();
        loadingTabulation.value = false;
      }else{
        $q.notify({
          message: "Ocorreu um erro, tente novamente.",
          color: "red-8",
          position: "top",
        });
        $q.loading.hide();
        loadingTabulation.value = false;
      }
    }).catch(err => {
      $q.notify({
          message: `${err.response.data ? err.response.data : err.message}`,
          color: "red-8",
          position: "top"
      });
      $q.loading.hide();
      loadingTabulation.value = false;
    });
  }else{
    await _paramsServices.updateFormChat(props.data.attendanceId, paramsForm).then((response)=>{
      if(response){
        $q.notify({
          message: "Formulário salvo com sucesso.",
          color: "green-8",
          position: "top",
        });
        $q.loading.hide();
        loadingTabulation.value = false;
      }else{
        $q.notify({
          message: "Ocorreu um erro, tente novamente.",
          color: "red-8",
          position: "top",
        });
        $q.loading.hide();
        loadingTabulation.value = false;
      }
    }).catch(err => {
      $q.notify({
          message: `${err.response.data ? err.response.data : err.message}`,
          color: "red-8",
          position: "top"
      });
      $q.loading.hide();
      loadingTabulation.value = false;
    });
  }

}


//TRAZER OS MOTIVOS DE ACORDO COM O ID DE TABULAÇÃO NA TABELA DE TICKET
async function _loadTabulation(){
  loadingTabulation.value = true;
  let controlOptionsArrayTabulation = [];
  let optionsAlreadySelect = [];

  if(isTabulation){
    for (let index = 0; index < props.tabulation_attendance.length; index++) {
      let optionSelect = props.tabulation_attendance[index];
      let optionsTabulation = (index <= 0) ? setOptionsTabulation() : (optionsAlreadySelect.push(props.tabulation_attendance[index - 1]), await _getOptionsTabulationsValue(index, optionsAlreadySelect));
      controlOptionsArrayTabulation.push({
          model: optionSelect,
          layer: index + 1,
          options : optionsTabulation
      });
    }
  }

  optionsArrayTabulation.value = controlOptionsArrayTabulation;
  loadingTabulation.value = false;
}

//SETAR A PRIMEIRA OPÇÃO DE TABULAÇÃO
function setOptionsTabulation(){
  let optionsTabulation = [];
  props.tabulation_queue.forEach((tabulation)=>{
    optionsTabulation.push({
      label: tabulation.descricao1,
      value: tabulation.descricao1
    });
  });
  return optionsTabulation;
}

//TRAZER OS VALORES DE CADA SELECT DE ACORDO COM O MOTIVO E NIVEL
async function _getOptionsTabulationsValue(layer, model){
  let optionsTabulation = [];
  await _treeServices.nextStepTabulation(props.data.idQueue, model).then((response)=>{
    if(response.data.length > 0){
      response.data.forEach((tabulation)=>{
        optionsTabulation.push({
          label: eval(`${'tabulation.descricao'}${layer + 1}`),
          value: eval(`${'tabulation.descricao'}${layer + 1}`)
        });
      });  
    }
  }).catch(err => {
    $q.notify({
        message: `${err.response.data ? err.response.data : err.message}`,
        color: "red-8",
        position: "top"
    })
  });
  return optionsTabulation;
}

//PREENCHER O QUE TIVER OPÇÃO NO FORMULÁRIO, RADIO, SELECT E ETC
function filterOptionsParam(options){
  let optionsParamSelect = [];

  options.forEach((option)=>{
    optionsParamSelect.push({
      label: option.parameterOptionValue,
      value: option.parameterOptionValue
    });
  });

  return optionsParamSelect;
}

//ATUALIZAR O SELECT DE ACORDO COM QUAL MOTIVO É SELECIONADO
async function updateSelect(value){
  $q.loading.show();
  let controlOptionsArrayTabulation = [];

  if(value.layer == 1){
    controlOptionsArrayTabulation = optionsArrayTabulation.value.filter(function(element) {
      return element.layer == 1;
    })
  }
  if(value.layer > 1){
    controlOptionsArrayTabulation = optionsArrayTabulation.value.filter(function(element) {
      return element.layer <= value.layer;
    })
  }

  let listDescription = [];
  controlOptionsArrayTabulation.forEach((tabulation)=>{
    listDescription.push(tabulation.model);
  });

  let optionsTabulation = await _getOptionsTabulationsValue(value.layer, listDescription);
  if(optionsTabulation.length > 0){ 
      controlOptionsArrayTabulation.push({
        model: "",
        layer: (value.layer + 1),
        options : optionsTabulation
      });
      optionsArrayTabulation.value = controlOptionsArrayTabulation;
  }else{
    optionsArrayTabulation.value.splice(value.layer);
  }
  $q.loading.hide();
}

//BUSCAR TODA A ARVORE DE TABULAÇÃO, CASO SEJA UMA TABULAÇÃO NOVA E PREENCHER O PRIMEIRO MOTIVO
function getTabulationCrea(){
  loadingTabulation.value = true;
  if(props.tabulation_queue.length > 0){
    let controlOptionsArrayTabulation = [];
    controlOptionsArrayTabulation.push({
        model: "",
        layer: 1,
        options : setOptionsTabulation()
    });
    optionsArrayTabulation.value = controlOptionsArrayTabulation;
    setTimeout(()=>{
      loadingTabulation.value = false;
    },1000);
  }else{
    optionsArrayTabulation.value = [];
    setTimeout(()=>{
      loadingTabulation.value = false;
    },1000);
  }
}

//LIMPAR O ID DE TABULAÇÃO 
async function clearTabulation(){
  $q.loading.show();
  if(props.isTicket){
    await _treeServices.clearIdTabulation(props.data.attendanceId).then((response)=>{
      if(response){
        emit('emitTabulation',{
                "idAttendance": props.data.attendanceId,
                "idTabulation": null
              });    
        $q.notify({
          message: "Tabulação resetada com sucesso.",
          color: "green-8",
          position: "top",
        });
      }else{
        $q.loading.hide(); 
        $q.notify({
          message: "Ocorreu um erro, tente novamente.",
          color: "red-8",
          position: "top",
        });
      }
    }).catch(err => {
      $q.loading.hide(); 
      $q.notify({
          message: `${err.response.data ? err.response.data : err.message}`,
          color: "red-8",
          position: "top"
      })
    });
  }else{
    await _treeServices.clearIdTabulationChat(props.data.attendanceId).then((response)=>{
      if(response){
        emit('emitTabulation',{
                "idAttendance": props.data.attendanceId,
                "idTabulation": null
              });    
        $q.notify({
          message: "Tabulação resetada com sucesso.",
          color: "green-8",
          position: "top",
        });
      }else{
        $q.loading.hide(); 
        $q.notify({
          message: "Ocorreu um erro, tente novamente.",
          color: "red-8",
          position: "top",
        });
      }
    }).catch(err => {
      $q.loading.hide(); 
      $q.notify({
          message: `${err.response.data ? err.response.data : err.message}`,
          color: "red-8",
          position: "top"
      })
    });
  }
}

//LIMPAR FORMULÁRIO E APAGAR A RELAÇÃO DO TICKET COM O FORMULÁRIO ANTIGO
async function clearForm(){
  $q.loading.show();
  //loadingTabulation.value = true;

  if(props.isTicket){
    await _paramsServices.clearForm(props.data.attendanceId).then(async (response)=>{
      if(response){
        emit('emitForm');   
        //loadingTabulation.value = false;
        $q.notify({
          message: "Formulário resetado com sucesso.",
          color: "green-8",
          position: "top",
        });
      }else{
        $q.loading.hide(); 
        //loadingTabulation.value = false;
        $q.notify({
          message: "Ocorreu um erro, tente novamente.",
          color: "red-8",
          position: "top",
        });
      }
    }).catch(err => {
      $q.loading.hide(); 
      //loadingTabulation.value = false;
      $q.notify({
          message: `${err.response.data ? err.response.data : err.message}`,
          color: "red-8",
          position: "top"
      })
    });
  }else{
    await _paramsServices.clearFormChat(props.data.attendanceId).then((response)=>{
      if(response){
        emit('emitForm');  
        //loadingTabulation.value = false;
        $q.notify({
          message: "Formulário resetado com sucesso.",
          color: "green-8",
          position: "top",
        });
      }else{
        $q.loading.hide(); 
        //loadingTabulation.value = false;
        $q.notify({
          message: "Ocorreu um erro, tente novamente.",
          color: "red-8",
          position: "top",
        });
      }
    }).catch(err => {
      $q.loading.hide(); 
      //loadingTabulation.value = false;
      $q.notify({
          message: `${err.response.data ? err.response.data : err.message}`,
          color: "red-8",
          position: "top"
      })
    });
  }
}



function getNextSelectList(item, data) {
  for (const itemKey in tabulation_queue_mock.value.parametro_tabulacao) {
    let obj = tabulation_queue_mock.value.parametro_tabulacao[itemKey];
    if (obj.tipo_parametro === 'list') {
      obj.selected = data[0].id_tabulador_parametros_lista_opcoes;
      if (obj.passo === (item.passo + 1)) {
        let cont = 0;
        obj.campos_opcoes_lista.filter((element) => {
          element.visible = obj.selected === element.pai;
          if(element.visible === true){
            cont += 1;
          }
        })
        obj.visible = cont > 0;
      }

      if (obj.passo >= (item.passo + 1)) {
        obj.valor_informacao = "";
      }

      if (obj.passo > (item.passo + 1)) {
        obj.visible = false;
      }
    }
  }
}

function showList(item){
  if(item.passo === null || parseInt(item.passo) === 1){
      return true;
  } else {
      let listBefore = getList(parseInt(item.passo) - 1);
      if(listBefore &&  listBefore.valor_informacao && listBefore.valor_informacao.toString().length > 0 && filterOptions(item).length > 0){
          return true;
      }
  }
}

function getList(step) {
  let [listFilter] = tabulation_queue_mock.value.parametro_tabulacao
                          .filter(item => parseInt(item.passo) === step);
  return listFilter;
}

function filterOptions(item){
  if(item.passo !== null && parseInt(item.passo) !== 1){
      let listBefore = getList(parseInt(item.passo) - 1);
      if(listBefore &&  listBefore.valor_informacao && listBefore.valor_informacao.toString().length > 0){
          return item.campos_opcoes_lista.filter(opcao =>{
              for (let i = 0; i < listBefore.campos_opcoes_lista.length; i++) {
                  if(opcao.pai == listBefore.campos_opcoes_lista[i].id_tabulador_parametros_lista_opcoes.toString()
                  && listBefore.valor_informacao == listBefore.campos_opcoes_lista[i].descricao_tpl_opcoes){
                      return opcao;
                  }
              }
          });
      }
  }
  return item.campos_opcoes_lista;
}


function resetNextSteps(item){
  tabulation_queue_mock.value.parametro_tabulacao.forEach((campoTmp) => {
        if(campoTmp.passo > item.passo){
            campoTmp.valor_informacao = '';
        }
    })
}

function _showTabulation() {

    if (props.tabulation_queue.parametro_tabulacao.length == 0) {
      loadingTabulation.value = false;
      return;
    }

    //props.loading = true;
    //loadingTabulation.value = true;

    showTabulation.value = props.tabulation_queue.parametro_tabulacao.length > 0;
    for (const argumentsKey in props.tabulation_queue.parametro_tabulacao) {
      let item = props.tabulation_queue.parametro_tabulacao[argumentsKey]
      tabulation_queue_mock.value = props.tabulation_queue;
    
      if (item.tipo_parametro === "list") {
      
        item.visible = true;
        item.selected = null;
      
        props.tabulation_queue.parametro_tabulacao[argumentsKey].campos_opcoes_lista.filter((element) => {
          element.visible = true
        })
      
        if (item.passo !== 1 && item.valor_informacao == null) {
          item.visible = false
        }
      }
    }
    //props.loading = false;
    loadingTabulation.value = false;
}

function _savedTabulation() {
  emit('tabulation_saved');
}

watchEffect(async () => {
  if(store.state.userData.id_empresa == 88){
    if(props.isOpen == true){
      props.tabulation_attendance.length > 0 ? _loadTabulation() : getTabulationCrea();
    }
  }else{
    _showTabulation();
  }
  // if(store.state.userData.id_empresa == 88 && props.isOpen == true){
  //   props.tabulation_attendance.length > 0 ? _loadTabulation() : getTabulationCrea();
  // }else{
  //   _showTabulation();
  // }
});

onMounted(()=>{
})


</script>
<style lang="scss" scoped>
.card-form-tabulation {
  max-height: 75vh;
  overflow-y: hidden;
}

.form-tabulation {
  max-height: 75vh;
  overflow-y: auto;
}

.buttons-tabulation{
  @media (max-width: $breakpoint-md-max){
    display: flex;
    flex-direction: column;
    button{
      width: 100%;
      margin-top: 8px;
    }
  }
  
  @media (min-width: $breakpoint-lg-min){
    display: flex;
    flex-direction: column;
    button{
      width: 100%;
      margin-top: 8px;
    }
  }

  @media (min-width: $breakpoint-xl-min){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.buttons-form{
  @media (max-width: $breakpoint-md-max){
    display: flex;
    flex-direction: column;
    button{
      width: 100%;
      margin-top: 8px;
    }
  }
  
  @media (min-width: $breakpoint-lg-min){
    display: flex;
    flex-direction: column;
    button{
      width: 100%;
      margin-top: 8px;
    }
  }

  @media (min-width: $breakpoint-xl-min){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

</style>