import http from '../http-common'

export default class QueueServices {

    async getAll(company_id){
        let res = await http.get(`v1/queue?companyId=${company_id}`);
        return res.data;
    }

    async search(queue_id){
        let res = await http.get(`v1/queue/${queue_id}`);
        return res.data;
    }

    async save(queue){
        let res = await http.post(`v1/queue`, queue);
        return res.data;
    }
    
    async edit(queue){
        let res = await http.put(`v1/queue/${queue.id_fila}`, queue);
        return res.data;
    }

    async disableAndEnableQueue(queue_id, id_status){
        let res = await http.delete(`v1/queue/${queue_id}/${id_status}`);
        return res.data;
    }

    async listQueues(company_id){
        let res = await http.get(`v1/queue/listar-filas?companyId=${company_id}`)
        return res.data
    }

    async getTabulationByAttendanceId(queueId, attendance_id)
    {
        let res = await http.get(`v1/queue/get-tabulation-by-attendance?queueId=${queueId}&attendanceId=${attendance_id}`);
        return res.data;
    }

    async saveVoiceQueue(queue)
    {
        let res = await http.post(`v1/queue/add-voice-queue`, queue);
        return res.data;
    }
}